import { TrackingService } from "../service/tracking.service";

/**
 * provides an initializer which inits all automatic tracking
 * 
 * @param trackingService
 */
export function TrackingInitializer(trackingService: TrackingService) {
    return () => new Promise<void>(resolve => {
        trackingService.onAppInitialization()
            .subscribe({ error: e => { } })
            .add(resolve);
    });
}