
<ng-template #modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modalDismiss()">
            <span aria-hidden="true">&times;</span>
        </button>

        <div *ngIf="progressNotif===1">
            <div class="glamour-container animate__animated animate__flipInX">
                <div class="starrays"></div>
                <span class="tiger-sprite huge-icon" [class]="'league-'+(winLadder.name | replace: ' ': '-' | lowercase)"></span>
            </div>
        </div>

        <div *ngIf="progressNotif>1">
            <div class="glamour-container animate__animated animate__flipInX">
                <div class="starrays" [class]="(queueData.ranking === 1) ? 'gold' : ''"></div>
                <span class="tiger-sprite" [class]="'huge-icon arena-top-winner-'+queueData.ranking"></span>
            </div> 
        </div>  

   
  
    
    </div>

   


    <!-- <span class="tiger-sprite huge-icon rank-1"></span> -->
    <div class="modal-body pb-5">
        <div class="row">


            <div *ngIf="progressNotif===1">
            <div class="col-12 col-sm-11 mx-auto text-center">
                <h1 class="tigerfont text-uppercase mb-4 animate__animated animate__fadeInUp animate__delay-500ms">{{ 'season.winLadder.title' | translate }}</h1>
            </div>
            <div class="col-12 col-sm-10 mx-auto text-center">
                <p class="subline mb-1 animate__animated animate__fadeInUp animate__delay-1s">
                    {{ 'season.winLadder.date' | translate: { result: data.result, date: (data.eventDate | todayDate) } }}
                </p>
                <span class="placement-highlight-large animate__animated animate__fadeInUp animate__delay-1s mb-4">{{ 'season.winLadder.league' | translate: { winLadder: winLadder.name } }}</span>
                <!-- <div class="rank-element rank-icon animate__animated animate__fadeInUp animate__delay-1s">
                    <span class="tiger-sprite huge-icon rank-1 position-relative float-none" style="top: 0px;" [class]="'rank-'+(winLadder.name | replace: ' ': '' | lowercase)+'-big'"></span>
                </div>
                <h2 class="tigerfont">{{ 'season.winLadder.result.'+data.result | translate }}</h2> -->
                <div class="animate__animated animate__fadeInUp animate__delay-2s" *ngIf="data?.bonus?.coins">
                    <p class="subline mb-3">{{ 'season.winLadder.bonus' | translate }}</p>
                    <div class="card gain single animate__animated animate__fadeInUp animate__delay-500ms mb-1">
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <span class="gain-label"><img src="/assets/images/coin-1.png">
                                    +<app-shared-countup [to]="data.bonus.coins" [duration]="2" [delay]="2"></app-shared-countup>
                                    {{ 'coins' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                 </div>
            </div>
            </div>



            <div *ngIf="progressNotif>1">
                <div class="modal-body pb-5">
                    <div class="row">
                        <div class="col-12 col-sm-11 mx-auto text-center">
                            <h1 class="tigerfont text-uppercase mb-4 animate__animated animate__fadeInUp animate__delay-500ms">{{ 'account.bets.slip.modalBestOf.title' | translate }}</h1>
                        </div>
                        <div class="col-12 col-sm-10 mx-auto text-center">
                       
                            <p class="subline mb-4 animate__animated animate__fadeInUp animate__delay-1s">
                                {{ 'account.bets.slip.modalBestOf.date' | translate: { date: (queueData.eventDate | todayDate) } }}
                                <span class="placement-highlight-large">{{ 'account.bets.slip.modalBestOf.ranking' | translate: { ranking: queueData.ranking } }}</span>
                                {{ 'account.bets.slip.modalBestOf.category' | translate: { category: queueData.category } }}
                            </p>
            
                            <div class="animate__animated animate__fadeInUp animate__delay-2s">
                                <p class="subline mb-3">{{ 'account.bets.slip.modalBestOf.bonus' | translate }}</p>
                                <div class="card gain single animate__animated animate__fadeInUp animate__delay-500ms mb-1" *ngIf="queueData?.bonus?.coins">
                                    <div class="row">
                                        <div class="col-12 col-sm-12">
                                            <span class="gain-label"><img src="/assets/images/coin-1.png">
                                                +<app-shared-countup [to]="queueData.bonus.coins" [duration]="2" [delay]="2"></app-shared-countup>
                                                {{ 'coins' | translate }}
                                            </span>
                                        </div>
                                    </div>
                                </div>                                   
                                <div class="card gain single animate__animated animate__fadeInUp animate__delay-500ms mb-1" *ngIf="queueData?.bonus?.xp">
                                    <div class="row">
                                        <div class="col-12 col-sm-12">
                                            <span class="gain-label"><img src="/assets/images/xp-1.png">
                                                +<app-shared-countup [to]="queueData.bonus.xp" [duration]="2" [delay]="2"></app-shared-countup>
                                                {{ 'xp' | translate }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card gain single animate__animated animate__fadeInUp animate__delay-500ms mb-1" *ngIf="queueData?.bonus?.booster">
                                    <div class="row">
                                        <div class="col-12 col-sm-12">
                                            <span class="gain-label"><img src="/assets/images/coin-1.png"> {{ 'booster.'+queueData.bonus.booster+'.title' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
            
                       </div>
                       <!-- <div class="col-12 col-sm-11 mx-auto text-center animate__animated animate__fadeInUp animate__delay-2500ms">
                            <button type="button" class="btn btn-primary btn-lg mx-auto text-uppercase tigerfont mt-4" (click)="modalButton()">
                              {{ 'account.bets.slip.modalBestOf.button' | translate }}
                            </button>
                            <p class="text-center mt-2 d-block d-md-none d-lg-none d-xl-none"><span class="cursor-pointer" (click)="modalDismiss()">{{ 'close' | translate }}</span></p>
                       </div> -->
                    </div>
               </div>

        </div>








            <div class="progress-bar-popup col-12 col-sm-11 mx-auto" >
       
                <div *ngIf="processBarNumberOfDots>1">
                    <div [class]="notificationsProgress(1)" (click)="progressSet(1)"></div>
                    </div>
                <div *ngIf="processBarNumberOfDots>1">
                <div [class]="notificationsProgress(2)" (click)="progressSet(2)"></div>
                </div>
                <div *ngIf="processBarNumberOfDots>2">
                <div [class]="notificationsProgress(3)" (click)="progressSet(3)"></div>
                </div>
                <div *ngIf="processBarNumberOfDots>3">
                <div [class]="notificationsProgress(4)" (click)="progressSet(4)"></div>
                </div>
             
              </div>
            <div [class]="nextButton()">
                <button type="button" class="btn btn-primary  mx-auto text-uppercase tigerfont mt-5 animate__animated animate__fadeInUp" [class]="getButtonAnimationDelay()" (click)="progressNext()">NEXT</button>
            </div>
            <div class="col-12 col-sm-11 mx-auto text-center">
                <button type="button" [class]="collectAll()" (click)="modalDismiss()">COLLECT ALL</button>
            </div>
        </div>
    </div>
</ng-template>

 