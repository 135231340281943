<ng-template #modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modalDismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <img [src]="logoPath()" class="mx-auto d-block img-fluid img-logo huge-icon" />
  <div class="modal-body pb-5">
    <form [formGroup]="registerFormular" (submit)="onSubmit()">
      <div class="row">
        <div class="col-11 col-sm-10 mx-auto text-center">
          <h2 class="tigerfont text-uppercase mb-3">
            {{ "register.title" | translate }}:
          </h2>
          <input type="text" formControlName="username" class="form-control form-control-lg mb-2 text-center"
            placeholder="{{ 'member.username.placeholder' | translate }}" />
          <input type="text" formControlName="email" class="form-control form-control-lg mb-2 text-center"
            placeholder="{{ 'member.email.placeholder' | translate }}" />
          <input type="password" formControlName="password" class="form-control form-control-lg mb-2 text-center"
            placeholder="{{ 'member.password.placeholder' | translate }}" />
          <div *ngIf="error" class="text-center">{{ error | translate }}</div>
          <div class="form-check mt-3">
            <input formControlName="terms" class="form-check-input" type="checkbox" value="1" id="register-terms" />
            <label class="form-check-label" for="register-terms">
              <ng-container *ngIf="translationService.getSelectedLocale() === 'de_DE'; else labelContainer">
                <a href="https://tigerking.de/static/terms">
                  {{ "register.formular.terms.label" | translate }}
                </a>
                {{ "register.formular.terms.and" | translate }}
                <a href="https://tigerking.de/static/privacy">
                  {{ "register.formular.terms.dataPolicy" | translate }}
                </a>
                {{ "register.formular.terms.accept" | translate }}
              </ng-container>
              <ng-template #labelContainer>
                {{ "register.formular.terms.accept" | translate }}
                <a href="https://tigerking.de/static/terms">
                  {{ "register.formular.terms.label" | translate }}
                </a>
                {{ "register.formular.terms.and" | translate }}
                <a href="https://tigerking.de/static/privacy">
                  {{ "register.formular.terms.dataPolicy" | translate }}
                </a>
              </ng-template>
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" formControlName="newsletter" type="checkbox" value="1"
              id="register-newsletter" />
            <label class="form-check-label" for="register-newsletter">
              {{ "register.formular.newsletter" | translate }}
            </label>
          </div>
        </div>
        <div class="col-11 col-sm-10 mx-auto text-center">
          <div class="register-bonus tigerfont mt-3 px-3 px-sm-0">
            <span class="tiger-sprite medium-icon coin float-left d-none d-sm-block"></span>{{ "register.formular.bonus"
            | translate
            }}<span class="lightfont"></span><span
              class="tiger-sprite medium-icon bet-correct-green float-right d-none d-sm-block"></span>
          </div>
        </div>
        <div class="col-11 col-sm-10 mx-auto text-center">
          <button type="submit" [disabled]="isLoading"
            class="btn btn-primary btn-lg mx-auto text-uppercase tigerfont mt-3">
            {{ "register.formular.submit" | translate }}
          </button>
          <p class="mt-4 mb-4">
            {{ "register.has-account" | translate }}
            <a href="#" (click)="onLogin()">{{
              "register.login" | translate
              }}</a>
          </p>
          <hr [attr.data-content]="'register.social.or' | translate" class="hr-text" />
          <button type="button" class="btn btn-google btn-block mx-auto mb-2 text-left" (click)="onGoogle()">
            <i class="fab fa-google"></i>
            {{ "register.social.google" | translate }}
          </button>
          <button type="button" class="btn btn-facebook btn-block mx-auto text-left" (click)="onFacebook()">
            <i class="fab fa-facebook"></i>
            {{ "register.social.facebook" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
