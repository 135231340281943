<div class="down-overlay" *ngIf="!isApiConnected">
  <div class="down-content">
    <div><img src="/assets/images/loading-logo.png" class="img-fluid" /></div>
    <h5>
      Es scheint als hätten wir grade ein paar Probleme. Bitte versuche es in
      ein paar Minuten noch einmal.
    </h5>
    <h6>
      Seems like we have some troubles. Please try it again in a few minutes.
    </h6>
  </div>
</div>
<router-outlet></router-outlet>
