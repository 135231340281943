import { Type } from "@angular/core";
import { ModalBetSlipBestOfComponent } from "../component/modal-bet-slip-best-of/modal-bet-slip-best-of.component";
import { ModalBetSlipDetailComponent } from "../component/modal-bet-slip-detail/modal-bet-slip-detail.component";
import { ModalBetSlipComponent } from "../component/modal-bet-slip/modal-bet-slip.component";
import { ModalBetWinsComponent } from "../component/modal-bet-wins/modal-bet-wins.component";
import { ModalConfirmComponent } from "../component/modal-confirm/modal-confirm.component";
import { ModalDefaultComponent } from "../component/modal-default/modal-default.component";
import { ModalInjectComponentComponent } from "../component/modal-inject-component/modal-inject-component.component";
import { ModalLoadingComponent } from "../component/modal-loading/modal-loading.component";
import { ModalLoginComponent } from "../component/modal-login/modal-login.component";
import { ModalPasswordResetComponent } from "../component/modal-password/modal-password.component";
import { ModalPaymentComponent } from "../component/modal-payment/modal-payment.component";
import { ModalRegisterComponent } from "../component/modal-register/modal-register.component";
import { ModalSeasonRankingComponent } from "../component/modal-season-ranking/modal-season-ranking.component";
import { ModalSeasonWinLadderComponent } from "../component/modal-season-win-ladder/modal-season-win-ladder.component";
import { ModalSystemBetComponent } from "../component/modal-system-bet/modal-system-bet.component";
import { ModalTaskCompleteComponent } from "../component/modal-task-complete/modal-task-complete.component";
import { ModalTutorialComponent } from "../component/modal-tutorial/modal-tutorial.component";
import { ModalVideoAdsComponent } from "../component/modal-videoads/modal-videoads.component";
import { ModalType } from "./modal-type.model";
import { ModalMinigamesComponent } from "../component/modal-minigames/modal-minigames.component";
/**
 * all modal components with its type as key
 */
export const ModalTypeComponentsMap: { [key in ModalType]?: Type<any> } = {
    [ModalType.Default]: ModalDefaultComponent,
    [ModalType.Success]: ModalDefaultComponent,
    [ModalType.Error]: ModalDefaultComponent,
    [ModalType.Confirm]: ModalConfirmComponent,
    [ModalType.InjectComponent]: ModalInjectComponentComponent,

    [ModalType.Loading]: ModalLoadingComponent,
    [ModalType.Login]: ModalLoginComponent,
    [ModalType.Register]: ModalRegisterComponent,
    [ModalType.PasswordReset]: ModalPasswordResetComponent,
    [ModalType.Tutorial]: ModalTutorialComponent,
    [ModalType.Payment]: ModalPaymentComponent,
    [ModalType.BetsWin]: ModalBetWinsComponent,
    [ModalType.BetSlip]: ModalBetSlipComponent,
    [ModalType.BetSlipBestOf]: ModalBetSlipBestOfComponent,
    [ModalType.BetSlipDetail]: ModalBetSlipDetailComponent,
    [ModalType.SystemBet]: ModalSystemBetComponent,
    [ModalType.TaskComplete]: ModalTaskCompleteComponent,
    [ModalType.SeasonRanking]: ModalSeasonRankingComponent,
    [ModalType.SeasonWinLadder]: ModalSeasonWinLadderComponent,
    [ModalType.VideoAds]: ModalVideoAdsComponent,
    [ModalType.VideoAds]: ModalVideoAdsComponent,
    [ModalType.Minigames]:ModalMinigamesComponent,
};
