import {Component} from '@angular/core';
import {ModalComponent} from '../modal/modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ModalController} from '../../model/modal-controller.model';
import {Modal} from '../../model/modal.model';
import {LabelService} from '../../../../core/label';

@Component({
  selector: 'app-modal-tutorial',
  templateUrl: './modal-tutorial.component.html',
  styleUrls: ['./modal-tutorial.component.scss'],
})
export class ModalTutorialComponent extends ModalComponent {
  /**
   * is true if there are no next slides left
   */
  public isAtEnd: boolean;

  /**
   * all slides
   */
  public slides = [];

  /**
   * checks on each slide change if the slider is at the end
   *
   * @param event
   */
  public onSlideChange(event: number) {
    this.isAtEnd = event + 1 === this.slides.length;
  }

  constructor(
    protected bsModalService: BsModalService,
    protected modalController: ModalController,
    protected modal: Modal,
    private labelService: LabelService
  ) {
    super(bsModalService, modalController, modal);
    this.slides = [
      {
        image: 'assets/images/tutorial-0.png',
        alt: '',
        description: 'tutorial.description.tutorial-0',
        additionalDesc: 'tutorial.additional-desc',
        title: 'tutorial.title',
        url: labelService.getHostname()
      },
      {
        image: 'assets/images/tutorial-1.png',
        alt: '',
        description: 'tutorial.description.tutorial-1',
      },
      {
        image: 'assets/images/tutorial-2.png',
        alt: '',
        description: 'tutorial.description.tutorial-2',
      },
      {
        image: 'assets/images/tutorial-3.png',
        alt: '',
        description: 'tutorial.description.tutorial-3',
      },
    ];
  }

  calculateFontSize(): number {
    const ulrLength = this.labelService.getHostname().length;
    if (ulrLength <= 20) {
      return 40;
    }
    if (ulrLength > 20) {
      return Math.round((800) / (ulrLength));
    }
  }
}
