import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../../../environments/environment";
const apiUrl = `${environment.api.request}/m2p/minigame/init`;
@Injectable({
  providedIn: 'root'
})
export class MinigameService {



  constructor(private http: HttpClient) {}

  getMinigame(id: number) {
    return this.http.get(`${apiUrl}?id=${id}`);
  }
}