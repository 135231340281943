import { SeasonService } from "../service/season.service";

/**
 * provides an initializer which loads the current
 * season and starts a timeout to reload it on end
 * 
 * @param seasonService
 */
export function SeasonInitializer(seasonService: SeasonService) {
    return () => new Promise<void>(resolve => {
        seasonService.getSeason()
            .subscribe({ error: e => { } })
            .add(resolve);
    });
}