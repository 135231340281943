<ng-template #modal>

   
    <button type="button" class="close-iframe close" aria-label="Close" (click)="modalDismiss()">
       <span aria-hidden="true">&times;</span>
    </button>
<div class="minigame-iframe-header">
    <div class="minigame-iframe-header-text">
        <ng-container >
            HALF COURT BASKETBALl
        </ng-container>
    </div>
</div>



<div class="minigame-iframe-body">

   

     <iframe [src]="data.url"></iframe>

</div>        







</ng-template>