import { Component } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { NotificationInterface,NotificationModeEnum, NotificationTypeEnum } from "src/app/front/core/notification";

import { TranslationService } from "../../../../../core/translation";
import { WinLadderInerface, WinLadderService } from "../../../../../core/winladder";
import { MemberService } from "../../../../core/member";
import { ModalController } from "../../model/modal-controller.model";
import { Modal } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";
import { ModalParameterInterface } from "../../interface/modal-parameter.interface";


@Component({
    selector: "app-modal-season-win-ladder",
    templateUrl: "./modal-season-win-ladder.component.html",
    styleUrls: ["./modal-season-win-ladder.component.scss"],
})
export class ModalSeasonWinLadderComponent extends ModalComponent {
    /**
     * the involved winladder
     */
    public winLadder: WinLadderInerface;
public progressNotif:number=1;
public notificationsQueue :NotificationInterface[] = []
public queueData:any={};
public processBarNumberOfDots:number;

    /**
     * tracks if the bonus is ready for count up
     */
    private showBonus: boolean;

    /**
     * inject dependencies
     *
     * @param translationService
     * @param winLadderService
     * @param memberService
     * @param bsModalService
     * @param modalController
     * @param modal
     */
    constructor(
        protected translationService: TranslationService,
        protected winLadderService: WinLadderService,
        protected memberService: MemberService,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal,

       // protected notif: NotificationComponent
    ) {
        super(bsModalService, modalController, modal);
        this.setDataFallback({ winLadderId: 0, result: "hold" });

  
            for(let i=0;i<this.data.queue.length;i++){
        if(this.data.queue[i].mode===NotificationModeEnum.BetSlipBestOf){
            this.notificationsQueue.push(this.data.queue[i])
        }
            }

if(this.notificationsQueue.length>4){
    this.processBarNumberOfDots=4;
}
else{
    this.processBarNumberOfDots=this.notificationsQueue.length+1;
}


    if(this.progressNotif ===1 && this.notificationsQueue.length>0){
        this.queueData= {
                eventDate: new Date(this.notificationsQueue[0].parameters.eventDate),
                betSlipId: this.notificationsQueue[0].parameters.betSlipId,
                ranking: this.notificationsQueue[0].parameters.ranking,
                category: this.notificationsQueue[0].parameters.category,
                bonus: {
                    coins: this.notificationsQueue[0].parameters.bonusCoins,
                }
            }
        }





        let nextwinLadder : any;
        if(this.data.result==='up'){
            nextwinLadder=this.data.winLadderId+1
        }
        if(this.data.result==='down'){
            nextwinLadder=this.data.winLadderId-1
        }
        this.winLadder = this.winLadderService.getById(nextwinLadder);
    }

    /**
     * reload member on open
     *
     * @param data
     */
    public onOpen(data?: any): void {
        this.memberService.reload().subscribe();
    
    }

    /**
     * returns the button fadein animation delay
     * depending on the possible win
     *
     * @returns
     */
    public getButtonAnimationDelay(): boolean {
    //    return this.data?.bonus?.coins ? "animate__delay-2500ms" : "animate__delay-1500ms";
    return this.data?.bonus?.coins ? true : false;   
}
   public notificationsProgress(x):string{
    if (x=== this.progressNotif){
        
        return "dot-popup-on"
    }
    else return "dot-popup"


   }
   public progressNext(){
  
        this.progressNotif++

        if(this.notificationsQueue.length>0 && this.progressNotif <  this.processBarNumberOfDots +1 ){
            this.queueData= {
                    eventDate: new Date(this.notificationsQueue[this.progressNotif-2].parameters.eventDate),
                    betSlipId: this.notificationsQueue[this.progressNotif-2].parameters.betSlipId,
                    ranking: this.notificationsQueue[this.progressNotif-2].parameters.ranking,
                    category: this.notificationsQueue[this.progressNotif-2].parameters.category,
                    bonus: {
                        coins: this.notificationsQueue[this.progressNotif-2].parameters.bonusCoins,
                    }
                }
            }
    
   }
  public  progressSet(progress:number){
    this.progressNotif=progress;
    if( this.progressNotif>1){
    if(this.notificationsQueue.length>0 && this.progressNotif <  this.processBarNumberOfDots +1  ){
        this.queueData= {
                eventDate: new Date(this.notificationsQueue[this.progressNotif-2].parameters.eventDate),
                betSlipId: this.notificationsQueue[this.progressNotif-2].parameters.betSlipId,
                ranking: this.notificationsQueue[this.progressNotif-2].parameters.ranking,
                category: this.notificationsQueue[this.progressNotif-2].parameters.category,
                bonus: {
                    coins: this.notificationsQueue[this.progressNotif-2].parameters.bonusCoins,
                }
            }
        }}
  }
  public nextButton(){
    if ((this.progressNotif< this.processBarNumberOfDots+1)&& (this.processBarNumberOfDots>1))
{return "col-12 col-sm-11 mx-auto text-center"}
else return "col-12 col-sm-11 mx-auto text-center dot-popup-display"
}
public collectAll(){
    if(this.getButtonAnimationDelay())
 {   if ((this.progressNotif< this.processBarNumberOfDots+1)  && (this.processBarNumberOfDots>1))
{return "btn  btn-lg mx-auto text-uppercase tigerfont mt-5 animate__animated animate__fadeInUp popupxx animate__delay-2500ms"}
else return "btn btn-primary  mx-auto text-uppercase tigerfont mt-5 animate__animated animate__fadeInUp collect-all animate__delay-2500ms"
}
else
{   if ((this.progressNotif< this.processBarNumberOfDots+1) && (this.processBarNumberOfDots>1))
    {return "btn  btn-lg mx-auto text-uppercase tigerfont mt-5 animate__animated animate__fadeInUp popupxx animate__delay-1500ms"}
    else return "btn btn-primary  mx-auto text-uppercase tigerfont mt-5 animate__animated animate__fadeInUp collect-all animate__delay-1500ms"
    }}
}
