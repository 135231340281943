import { KeyValue } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { listLocales } from "ngx-bootstrap/chronos";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { MemberService } from "../../../../../../core/member";
import { TranslationService } from "../../../../../../core/translation";
import { FromHelper } from "../../../../../../shared/form";
import {
    BettingGameInterface,
    BettingGameLeagueSelectInterface,
    BettingGameLeagueService,
    BettingGameService,
} from "../../../../../core/betting-game";
import { MemberInterface } from "../../../../../core/member";
import { ModalService } from "../../../../../shared/modal";
import { ModalComponent } from "../../../../../shared/modal/component/modal/modal.component";

@Component({
    selector: "app-bettinggame-dashboard-includes-creation",
    templateUrl: "./creation.component.html",
    styleUrls: ["./creation.component.scss"],
})
export class CreationComponent implements OnInit, OnDestroy {
    /**
     * tracks if the formular is currently saving
     */
    public isSaving: boolean;

    /**
     * tracks if the formular is enabled or not
     */
    public isEnabled: boolean;

    /**
     * an error string
     */
    public error: string;

    /**
     * the formular to use
     */
    public creationFormular: FormGroup;

    /**
     * member subscription
     */
    private memberSubscription: Subscription;

    /**
     * all known selectable leagues
     */
    public leaguesSelect: BettingGameLeagueSelectInterface;

    /**
     * the modal containing this component
     */
    private parentModal: ModalComponent;

    constructor(
        private leagueService: BettingGameLeagueService,
        private bettingGameService: BettingGameService,
        private translationService: TranslationService,
        private bsLocaleService: BsLocaleService,
        private memberService: MemberService,
        private modalService: ModalService,
        private formBuilder: FormBuilder,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.buildFormular();
        this.loadLeagues();
        this.subscribeMemberObservable();
        //this.setBsLocale();
    }

    public ngOnDestroy(): void {
        this.unsubscribeMemberObservable();
        //this.clearBettingGameLists();
    }

    /**
     * This function sets the parent modal of the current modal
     *
     * @param {ModalComponent} modal - ModalComponent - This is the modal component that will be used
     * to open the modal.
     */
    public setParentModal(modal: ModalComponent): void {
        this.parentModal = modal;
    }

    /**
     * If the form is enabled and not saving, then the user can submit the form
     * @returns A boolean value.
     */
    public canSubmit(): boolean {
        return this.isEnabled && !this.isSaving;
    }

    /**
     * on submit the formular
     */
    public onSubmit(): boolean {
        if (this.creationFormular.invalid) {
            this.onFormErrors();
            return false;
        }
        this.save();
        return false;
    }

    /**
     * close the parent modal
     */
    public close(): void {
        this.parentModal.close();
    }

    /**
     * builds the formular
     */
    private buildFormular(): void {
        this.creationFormular = this.formBuilder.group({
            name: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(32),
                    Validators.pattern(/^[\p{L}0-9]+/iu),
                ],
            ],
            description: [null],
            league: [null, Validators.required],
            //closeAt: [null, [DateValidator({ onInvalid: null, maxDate: new Date() })]],
        });
    }

    /**
     * subscribe member observable
     */
    private subscribeMemberObservable(): void {
        this.memberSubscription = this.memberService
            .getMemberObservable()
            .pipe(
                tap((member: MemberInterface) => {
                    if (member === null) {
                        setTimeout(() => this.parentModal.close(), 250);
                    }
                })
            )
            .subscribe();
    }

    /**
     * unsubscribe member observable
     */
    private unsubscribeMemberObservable(): void {
        if (this.memberSubscription) {
            this.memberSubscription.unsubscribe();
        }
    }

    /**
     * load known lwagues from API
     */
    private loadLeagues(): void {
        this.leagueService.getSelectable().subscribe({
            next: (leaguesSelect: BettingGameLeagueSelectInterface) => {
                // enable the formular and set the selectable leagues
                // we use as options
                this.leaguesSelect = leaguesSelect;
                this.isEnabled = true;
                // preselect an option
                const preSelectKey = Object.keys(leaguesSelect.popular)[0];
                const preSelectLeague = leaguesSelect.popular[preSelectKey];
                this.creationFormular.patchValue({
                    league: preSelectLeague.leagueId,
                });
            },
            error: (response: any) => {
                setTimeout(() => {
                    this.parentModal.close();
                    this.modalService.openError();
                }, 250);
            },
        });
    }

    /**
     * try to create the new bettings game
     */
    private save(): void {
        this.error = "";
        this.isSaving = true;
        const formData = FromHelper.getFormValues(this.creationFormular);
        this.bettingGameService.create(formData).subscribe({
            next: (bettingGame: BettingGameInterface) => {
                this.isSaving = false;
                this.onSaveSuccess(bettingGame);
            },
            error: (response: any) => {
                this.isSaving = false;
                this.onServerErrors(response);
            },
        });
    }

    /**
     * handles form errors
     */
    private onFormErrors(): void {
        const e = FromHelper.getFirstValidationError(this.creationFormular);
        //this.modalService.openError("bettingGame." + errors[i].control + ".error." + errors[i].error);
        this.error = "bettingGame.default." + e.control + ".error." + e.error;
    }

    /**
     * The function is called when the server returns an error and
     * handles the error in the defined way
     *
     * @param {any} response - any
     */
    private onServerErrors(response: any): void {
        // some unknown error occured
        if (!response?.error?.data) {
            this.parentModal.close();
            this.modalService.openError();
            return;
        }
        // switch the error to handle
        // special error messages
        switch (response?.error?.data) {
            // if the member has not enough coins to create
            // a betting group we open a new modal
            case "coins":
                //this.parentModal.close();
                this.modalService.openDefault({
                    title: "bettingGame.default.coins.error.title",
                    message: "bettingGame.default.coins.error.message",
                    note: "bettingGame.default.coins.error.tip",
                    buttonConfirm: "bettingGame.default.coins.error.button",
                    redirectTo: "/shop/coins",
                });
                break;
            // any other error is an default error...
            default:
                this.error = "bettingGame.default." + response.error.data;
                break;
        }
    }

    /**
     * It closes the modal, and opens a new modal with a success message
     *
     * @param {BettingGameInterface} bettingGame - BettingGameInterface - this is the object that is
     * returned from the server after the save is successful.
     */
    private onSaveSuccess(bettingGame: BettingGameInterface): void {
        this.close();
        this.router.navigate(["/betting-game", bettingGame.uuid, "settings"]);
        this.modalService.openDefault({
            title: "bettingGame.dashboard.creation.success.text",
        });
    }

    /**
     * set bootstrap locale to display the correct date names and format them correctly
     */
    private setBsLocale(): void {
        if (listLocales().find((locale) => locale === this.translationService.getSelectedLocale())) {
            this.bsLocaleService.use(this.translationService.getSelectedLocale());
        } else {
            this.bsLocaleService.use(this.translationService.getSelectedLocale());
        }
    }

    /**
     * keep origin order
     *
     * @param a
     * @param b
     * @returns 0
     */
    public orderOrigin(a: KeyValue<number, any>, b: KeyValue<number, any>): number {
        return 0;
    }

    /**
     * orders by the location or league name
     * 
     * @param a
     * @param b
     * @returns 0, -1 or 1.
     */
    public orderByName(a: KeyValue<string, any>, b: KeyValue<string, any>): number {
        const valA = a.value.locationName || a.value.leagueName;
        const valB = b.value.locationName || b.value.leagueName;
        return (valA === valB) ? 0 : (valA > valB) ? 1 : -1;
    }
}
