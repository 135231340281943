<ng-template #modal>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="modalDismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <img
    [src]="logoPath()"
    class="mx-auto d-block img-fluid img-logo huge-icon"
  />
  <div class="modal-body pb-5">
    <form [formGroup]="loginFormular" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-11 col-sm-10 mx-auto text-center">
          <h2 class="tigerfont text-uppercase mb-3">
            {{ "login.title" | translate }}
          </h2>
          <p class="mb-4">{{ "login.message" | translate }}</p>
          <input
            type="text"
            formControlName="username"
            class="form-control mb-2 text-center form-control-lg"
            placeholder="{{
              'login.formular.username.placeholder' | translate
            }}"
            [id]="inputUsernameId"
          />
          <input
            type="password"
            formControlName="password"
            class="form-control mb-2 text-center form-control-lg"
            placeholder="{{
              'login.formular.password.placeholder' | translate
            }}"
            [id]="inputPasswordId"
          />
          <div *ngIf="error" class="text-center">{{ error | translate }}</div>
          <a href="#" class="mt-3" (click)="onPassword()">{{
            "login.password-forgot" | translate
          }}</a>
        </div>
        <div class="col-11 col-sm-10 mx-auto text-center">
          <button
            type="submit"
            class="btn btn-primary btn-lg mx-auto text-uppercase tigerfont btn-block mb-4 mt-4"
            (click)="onSubmit()"
            [disabled]="isLoading"
          >
            <span *ngIf="!isLoading">{{
              "login.formular.submit" | translate
            }}</span>
            <span *ngIf="isLoading">{{
              "login.loading.button" | translate
            }}</span>
          </button>
          <p class="mb-4">
            {{ "login.no-account" | translate }}
            <a href="" (click)="onRegister()">{{
              "login.register" | translate
            }}</a>
          </p>
          <hr
            [attr.data-content]="'login.social.or' | translate"
            class="hr-text"
          />
          <button
            type="button"
            class="btn btn-google btn-block mx-auto mb-2 text-left"
            (click)="onGoogle()"
          >
            <i class="fab fa-google"></i>
            {{ "login.social.google" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-facebook btn-block mx-auto text-left"
            (click)="onFacebook()"
          >
            <i class="fab fa-facebook"></i>
            {{ "login.social.facebook" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
