import { Injectable } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { MemberInterface, MemberService } from "../../../front/core/member";
import moment from "moment";

// setting up locales for bootstrap
import { defineLocale } from "ngx-bootstrap/chronos";
import { deLocale } from "ngx-bootstrap/locale";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Context } from "../interface/context";
defineLocale("de", deLocale);

@Injectable({
    providedIn: "root",
})
export class TranslationService extends TranslateService {


    onLocaleChange: Observable<string> = new BehaviorSubject(this.getSelectedLocale());

    /**
     * currently used context so we can choose the corrct dictionary
     */
    private context: Context = "tigerking";
    public setContext(context: Context) {
        this.context = context;
        this.selectLocale(this.getSelectedLocale() || this.getPreferableFallbackLocale())
    }


    /**
     * formats a number (decimal and group seperators) and returns it as string
     *
     * @param number
     * @param roundTo
     * @param language
     */
    public formatNumber(number: number, roundTo?: number, language?: string): string {
        const lang = language || this.getSelectedLocale();
        // get number to format
        const toFormat = roundTo ? number.toFixed(roundTo) : number;
        //  return formated string
        return toFormat.toLocaleString(lang.replace("_","-"));
    }

    /**
     * returns the (current) date formated
     * for the current language/locale
     *
     * @param date
     * @returns
     */
    public formatDate(date?: Date): string {
        const toFormat = date ? date : new Date();
        const format = this.getDateformat();
        return moment(toFormat).format(format);
    }

    /**
     * returns a number seperator (group or decimal) for the
     * currently used or passed language
     *
     * NOTE: I would prefer using the the intl functions for
     * this task but sadly angular fires an error if I try
     * to use it
     *
     * @param type
     * @param language
     */
    public getNumberSeparator(type: "decimal" | "group", language?: string): string {
        const lang = (language || this.getSelectedLocale()).replace("_",'-');
        return this.getNumberSeparatorByIntl(type, lang) || this.getNumberSeparatorByRegEx(type, lang);
    }

    /**
     * returns the date format like "DD.MM.YYYY"
     * for the current or passed language
     *
     * @param language
     */
    public getDateformat(language?: string): string {
        const lang = (language || this.getSelectedLocale()).replace("_","-")
        const locale = moment.locale();

        moment.locale(lang);
        const localeData = moment.localeData();
        const format = localeData.longDateFormat("L");
        moment.locale(locale);
        return format;
    }

    /**
     * init the app lamguage by locale storage, member language, browser or fallback
     *
     * @param memberService
     */
    public async onAppInitialization(memberService: MemberService): Promise<boolean> {
        // set all supported languages (we load the translations on request)
        this.addLangs(environment.translation.locales);
        // resolve the default locale to use.
        await this.selectLocale(this.getPreferableFallbackLocale());

        memberService
            .getMemberObservable()
            .pipe(filter((member: MemberInterface) => member !== null))
            .subscribe((member: MemberInterface) => {
                this.selectLocale(member.locale);
            });
        // just return true
        return true;
    }

    /**
     * It imports the locale module for the given language and registers it with the Angular framework
     *
     * @param {string} language - string - the language to load
     */
    private async initializeLocaleModule(language: string): Promise<void> {
        let moduleImportPromise: any;
        switch (language) {
            case "de":
                moduleImportPromise = import("@angular/common/locales/de");
                break;

            case "en":
                moduleImportPromise = import("@angular/common/locales/en");
                break;

            case "es":
                moduleImportPromise = import("@angular/common/locales/es");
                break;

            case "fr":
                moduleImportPromise = import("@angular/common/locales/fr");
                break;

            case "it":
                moduleImportPromise = import("@angular/common/locales/it");
                break;

            case "nl":
                moduleImportPromise = import("@angular/common/locales/nl");
                break;

            case "pl":
                moduleImportPromise = import("@angular/common/locales/pl");
                break;

            default:
                moduleImportPromise = import("@angular/common/locales/de");
                break;
        }
        const module = await moduleImportPromise.then((m: any) => m.default);
        registerLocaleData(module);
    }

    /**
     * loads a new language by importing the dictionary
     * to use and set up the translation
     *
     * @param language - string - example: en
     * @param context - string - 1OF tigerking, tcp
     * @returns
     */
    private async loadNewTranslation(locale: string, context: Context = this.context): Promise<void> {
        const translationFile = await this.fetchTranslationFile(locale, context);
        this.setTranslation(locale, translationFile);
    }


    private async fetchTranslationFile(locale: string, context: Context = this.context) {
        return import("src/assets/i18n/" + context + "." + locale + ".json")
    }

    /**
     * use the given language and if the language is not initialized we need to do this first and if that fail,
     * we switch to the default language
     * User => LocalStorage => Browser => Fallback
     * @param {string} locale - string - en_US - de_DE
     * @returns OnSuccess => Resolved Promise, onFailure Rejected Promise.
     */
    public async selectLocale(locale: string, context: Context = this.context): Promise<void> {
        await this.initializeLocaleModule(locale.split("_")[0])
        await this.loadNewTranslation(locale, context);
        await this.use(locale);
        localStorage.setItem(environment.translation.localStorageKey, locale);
        (this.onLocaleChange as Subject<string>).next(locale);
    }

    public getSelectedLocale() {
        return this.currentLang
            || localStorage.getItem(environment.translation.localStorageKey);
    }

    public getPreferableFallbackLocale() {
        const { locales, fallback } = environment.translation;

        const storage = localStorage.getItem(environment.translation.localStorageKey);
        const browser = this.getBrowserCultureLang().replace("-","_")
        // localstorage must contains only valid locales.
        if (storage) return storage;
        // browser locale can be unknown.
        if (browser) {
            if (locales.includes(browser)) {
                return browser;
            }
            // Choose using language only (firstpart from the locale)
            const localeWithSameLanguage = locales.find(locale => locale.startsWith(browser.split("-")[0]));
            if (localeWithSameLanguage) return localeWithSameLanguage
        }
        return fallback.replace(/-/g, "_")
    }
    /**
     * it tries to get the number separator from the Intl object, but if it can't, it returns an empty
     * string.
     *
     * @param type
     * @param language
     * @returns
     */
    private getNumberSeparatorByIntl(type: "decimal" | "group", language: string): string {
        // number to do magic with ;)
        const toFromat = 1000.1;
        // try to use intl (but it's not sure we can access it)
        if (typeof Intl.NumberFormat.prototype.formatToParts !== "undefined") {
            try {
                // try to get the seperator result
                const seperator = Intl.NumberFormat(language.replace("_","-"))
                    .formatToParts(toFromat)
                    .find((part) => part.type === type).value;
                if (seperator) {
                    return seperator;
                }
            } catch (error) { }
        }
        return "";
    }

    /**
     * it takes a number, formats it to a locale, and then extracts the separators from the formatted
     * string
     *
     * @param type
     * @param language
     * @returns
     */
    private getNumberSeparatorByRegEx(type: "decimal" | "group", language: string): string {
        // number to do magic with ;)
        const toFromat = 1000.1;
        const matches = /^[0-9]+([^0-9]*)[0-9]+([^0-9]+)[0-9]+$/gim.exec(toFromat.toLocaleString(language.replace("_","-")));
        if (matches === null) {
            return "";
        }
        if (type === "group") {
            return matches[1];
        }
        if (type === "decimal") {
            return matches[2];
        }
        return "";
    }
}
