import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { TranslationService } from "../../../core/translation";

@Component({
    selector: "app-shared-countup",
    templateUrl: "./countup.component.html",
    styleUrls: ["./countup.component.scss"],
})
export class CountupComponent implements OnInit, OnChanges {
    /**
     * is true if the countup is complete
     */
    public isComplete: boolean;

    /**
     * thats the real number we count to,
     * we need that to provide a delay
     * before we start to count up
     */
    public countTo: number;

    /**
     * number to count to
     */
    @Input()
    public to: number = 0;

    /**
     * number to start counting from (default 0)
     */
    @Input()
    public from: number = 0;

    /**
     * decimal places option (default no limit)
     */
    @Input()
    private decimal: number;

    /**
     * decimal place seperator (default detected by the translator)
     */
    @Input()
    private seperatorDecimal: string;

    /**
     * thousand place seperator (default detected by the translator)
     */
    @Input()
    private seperatorThousand: number;

    /**
     * the countup duration in seconds (default 1.5 sec)
     */
    @Input()
    private duration: number;

    /**
     * the countup delay in seconds like 1.5 (default 0 ms)
     */
    @Input()
    private delay: number;

    /**
     * we keep track of the countdown delay timer to abort it
     * on new changes
     */
    private countUpTimeout: ReturnType<typeof setTimeout> | null = null;

    constructor(private translationService: TranslationService) {
        this.countTo = 0;
    }

    /**
     * let the countdown begin
     */
    public ngOnInit(): void {}

    /**
     * if the passed values change we (re)start the
     * countdown
     *
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        this.start();
    }

    /**
     * if countup is complete, we just set that to isComplete
     */
    public onComplete(): void {
        this.isComplete = true;
    }

    /**
     * returns the current language setting
     */
    public currentLang(): string {
        return this.translationService.getSelectedLocale();
    }

    /**
     * starts the countdown
     * 
     * @returns 
     */
    private start(): void {
        // because the decimal places option only take
        // effect for the count up but not the final
        // value we display, we need to take care of
        // the decimal places by our self
        if(typeof this.decimal === 'number' && this.decimal >= 0){
            const modifier = Math.pow(10, this.decimal);
            this.to = Math.round(this.to * modifier)/modifier
        }
        // if the input value to count to is the same
        // as the one we use to set up the countup
        // we are done and we dont need to start it
        if (typeof this.to !== "number" || this.to === this.countTo) {
            return;
        }

        // if there is allready a timout set, we
        // cancel it so we can start a new one
        // if necessary
        if (this.countUpTimeout !== null) {
            clearTimeout(this.countUpTimeout);
            this.countUpTimeout = null;
        }
        // starts to countup after a delay
        if (typeof this.delay === "number" && this.delay > 0) {
            this.countUpTimeout = setTimeout(() => {
                this.startCountUp();
            }, this.delay * 1000);
            return;
        }
        // start counup directly
        this.startCountUp();
    }

    /**
     * starts to count up by setting
     * isComplete to false and the
     * new value to count to
     */
    private startCountUp(): void {
        this.isComplete = false;
        this.countTo = this.to;
    }

    /**
     * returns the default options with the inputs
     * of this component
     *
     * @returns
     */
    public getCountUpOptions(): { [key: string]: any } {
        const extension: { [key: string]: any } = {};
        if (this.from) {
            extension.startVal = this.from;
        }
        if (typeof this.decimal === 'number' && this.decimal >= 0) {
            extension.decimalPlaces = this.decimal;
        }
        if (this.duration) {
            extension.duration = this.duration;
        }
        if (this.seperatorDecimal) {
            extension.decimal = this.seperatorDecimal;
        }
        if (this.seperatorThousand) {
            extension.separator = this.seperatorThousand;
        }
        return {
            ...this.getCountUpDefaultOptions(),
            ...extension,
        };
    }

    /**
     * returns localized count up options
     *
     * @returns
     */
    private getCountUpDefaultOptions(): { [key: string]: any } {
        return {
            startVal: 0,
            duration: 1.5,
            decimal: this.translationService.getNumberSeparator("decimal"),
            separator: this.translationService.getNumberSeparator("group"),
        };
    }
}
