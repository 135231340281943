import { Injectable } from '@angular/core';
import { ActiveToast } from 'ngx-toastr';
import { first, take } from 'rxjs/operators';
import { NotificationInterface, NotificationModeEnum, NotificationTypeEnum } from '..';
import { ContentInterface, ToastrService } from '../../../../shared/toastr';
import { ModalBetSlipBestOf, ModalController, ModalEventType, ModalSeasonRanking, ModalSeasonWinLadder, ModalService } from '../../../shared/modal';

@Injectable({
    providedIn: 'root'
})
export class NotificationHandlerService {
    /**
     * all notifications become queued in this var
     * to make sure we open only one Modal at the 
     * time but dont miss to open any of them
     */
    private queuedNotifications: NotificationInterface[] = []
    private queuedNotificationsShared: NotificationInterface[] = []


    /**
     * tracks if the queue is handled at the moment 
     * (so we dont need to start a new handle)
     */
    private isQueueHandling: boolean;

    /**
     * all bet slip ids orderd by win, lose and refund
     * (because we summarize all won bet slips to avoid
     * an anoying amount of bet slip notifications)
     */
    private betSlips: {
        win: Array<{ betSlipId: number, coinsWin: number, xpWin: number }>,
        lose: Array<{ betSlipId: number, coinsWin: number, xpWin: number }>,
        refund: Array<{ betSlipId: number, coinsWin: number, xpWin: number }>
    } = { win: [], lose: [], refund: [] };


    /**
     * inject and create dependencies
     * 
     * @param modalService 
     * @param toastrService 
     */
    public constructor(
        private modalService: ModalService,
        private toastrService: ToastrService
        
    ) { }

    /**
     * takes a single or a set of notification(s) and
     * starts to handle them and if they are modal
     * notifications they only open one by one to
     * prevent an modal overflow
     * 
     * @param notifications 
     */
    public open(notifications: NotificationInterface | NotificationInterface[]): void {
        const notificationsToQueue = Array.isArray(notifications) ? notifications : [notifications];
        this.queue(notificationsToQueue);
    }

    /**
     * takes a set of notifications and starts to handle
     * them and if they are modal notifications they
     * only open one by one
     * 
     * @param notifications 
     */
    public queue(notifications: NotificationInterface[]): void {
     
        this.pushToQueue(notifications);
        this.startToHandle();
    }

    /**
     * starts to handle the queue if the handling
     * is not allready running
     * 
     * @returns 
     */
    private startToHandle(): void {
        // check if we can start to handle the queue
        if (!this.canStartToHandle()) {
            return;
        }
        // start to handle the queue
        this.handleQueue();
    }

    /**
     * handles the notification queue
     */
    private handleQueue(): void {
        // note that we start to handle the queue so
        // we dont start the handle twice
        this.isQueueHandling = true;

        // if there is a queue we handle the notification
        if (this.hasQueue()) {
            const notification = this.queuedNotifications.splice(0, 1);

            const modalController = this.handleNotification(notification[0]);
            this.handleNext(modalController);
        }
        // else if there are no notifications left, we
        // handle the registered BetSlipNotifications
        else {
            this.handleBetSlipWinNotification();
            this.handleBetSlipLoseNotification();
            this.handleBetSlipRefundNotification();
            this.isQueueHandling = false;
        }
    }

    /**
     * tells the service to handle the next notification
     * from the queue
     * 
     * @param modalController 
     * @returns 
     */
    private handleNext(modalController: ModalController | void): void {
        // if there is no modalControler, we cant chain, so we just continue
        // to handle the queue as before
        if (!modalController) {
            return this.handleQueue();
        }
        // if we have a modal controller, we chain the next handle, so that
        // we go on if the modal becomes closed
        modalController.onModalEvent(ModalEventType.Closed)
            .pipe(first())
            .subscribe(() => this.handleQueue());
    }

    /**
     * add notifications to queue (without handling them)
     * 
     * @param notifications 
     */
    private pushToQueue(notifications: NotificationInterface[]): void {

    this.queuedNotificationsShared = this.queuedNotifications.concat(notifications)
      let latestSeasonUpdateItem = null;
      notifications.forEach(item => {
        if (item.mode === NotificationModeEnum.SeasonWinLadderUpdate && (!latestSeasonUpdateItem || item.insertDate > latestSeasonUpdateItem.insertDate)) {
          latestSeasonUpdateItem = item;
        }
      });
      notifications = notifications.filter(item => item.mode !== NotificationModeEnum.SeasonWinLadderUpdate);
      if (latestSeasonUpdateItem) {
        notifications.push(latestSeasonUpdateItem);
      }
      this.queuedNotifications = this.queuedNotifications.concat(notifications);
    }

    /**
     * returns true if there are any notifications left in the queue
     * 
     * @returns 
     */
    private hasQueue(): boolean {
        return (this.queuedNotifications.length > 0);
    }

    /**
     * returns true if we can start a new queue handle,
     * this is the case if we are not currently handle 
     * the queue and there are notifications in the queue
     * left
     * 
     * @returns 
     */
    private canStartToHandle(): boolean {
        return (!this.isQueueHandling && this.hasQueue());
    }

    /**
     * handles a notifications by its mode
     * 
     * @param notifications 
     */
    private handleNotification(notification: NotificationInterface): ModalController | void {
        switch (notification.mode) {
            case NotificationModeEnum.BetSlipWin:
                return this.registerBetSlipNotification('win', notification);

            case NotificationModeEnum.BetSlipLose:
                return this.registerBetSlipNotification('lose', notification);

            case NotificationModeEnum.BetSlipRefund:
                return this.registerBetSlipNotification('refund', notification);

            // case NotificationModeEnum.BetSlipBestOf:
            //     return this.handleBetSlipBestOfNotification(notification);

            case NotificationModeEnum.AchievementComplete:
                return this.handleAchievementNotification(notification);

            case NotificationModeEnum.DailyMissionComplete:
                return this.handleDailyMissionNotification(notification);

            case NotificationModeEnum.SeasonRanking:
                return this.handleSeasonRankingNotification(notification);

            case  NotificationModeEnum.SeasonWinLadderUpdate:
                return this.handleSeasonWinLadderUpdateNotification(notification);

            case NotificationModeEnum.Modal:
                return this.handleModalNotification(notification);

            case NotificationModeEnum.Notify:
                return this.handleNofifyNotification(notification);
        }
    }

    /**
     * handles a notification by open a modal by notification type
     * 
     * @param notification 
     */
    private handleModalNotification(notification: NotificationInterface): void {
        switch (notification.type) {
            case NotificationTypeEnum.Success:
                this.modalService.openSuccess({
                    title: notification.parameters.title,
                    message: notification.parameters.message
                });
                break;
            case NotificationTypeEnum.Info:
            case NotificationTypeEnum.Danger:
            case NotificationTypeEnum.Warning:
                this.modalService.openDefault({
                    title: notification.parameters.title,
                    message: notification.parameters.message
                });
                break;
            case NotificationTypeEnum.Error:
                this.modalService.openError(notification.parameters.message);
                break;
        }
    }

    /**
     * handles a notification by showing a toastr notification by type
     * 
     * @param notification 
     */
    private handleNofifyNotification(notification: NotificationInterface): void {
        // toastr content
        const content: ContentInterface = {
            title: notification.parameters.title,
            message: notification.parameters.message
        };
        // show toastr
        switch (notification.type) {
            case NotificationTypeEnum.Success:
                this.toastrService.success(content);
                break;
            case NotificationTypeEnum.Info:
                this.toastrService.info(content);
                break;
            case NotificationTypeEnum.Warning:
                this.toastrService.warning(content);
                break;
            case NotificationTypeEnum.Danger:
            case NotificationTypeEnum.Error:
                this.toastrService.error(content);
                break;
        }
    }

    /**
     * register a win notification
     * 
     * @param notification 
     */
    private registerBetSlipNotification(type: 'win' | 'lose' | 'refund', notification: NotificationInterface): void {
        this.betSlips[type].push({
            betSlipId: notification.parameters.betSlipId,
            coinsWin: notification.parameters.coinsWin,
            xpWin: notification.parameters.xpWin
        });
    }

    /**
     * opens the bet slips win modal
     * 
     * @param notification 
     */
    private handleBetSlipWinNotification(): void {
        if (this.betSlips.win.length === 0) {
            return;
        }
        const betSlips = this.betSlips.win;
        this.betSlips.win = [];
        this.modalService.openBetWins(betSlips);
    }

    /**
     * opens a toastr with the lose notification, which opens the bet slip modal on click/tap
     * 
     * @param notification 
     */
    private handleBetSlipLoseNotification(): void {
        if (this.betSlips.lose.length === 0) {
            return;
        }
        const betSlipSummaries = this.betSlips.lose;
        this.betSlips.lose = [];
        // generate an array of betSlipIds
        const betSlipIds = betSlipSummaries.map(summary => summary.betSlipId);
        // toastr content
        const content: ContentInterface = {
            title: 'account.bets.slip.notification.lose',
            message: 'account.bets.slip.notification.more'
        };
        // show toastr
        this.toastrService.info(content, { betSlips: betSlipIds.length }).then((toastr: ActiveToast<any>) => {
            toastr.onTap.pipe(take(1)).subscribe(() => this.modalService.openBetSlipsDetail(betSlipIds, 'lose'));
        });
    }

    /**
     * opens a toastr with a refund notification, which opens the bet slip modal on click/tap
     * 
     * @param notification 
     */
    private handleBetSlipRefundNotification(): void {
        if (this.betSlips.refund.length === 0) {
            return;
        }
        const betSlipSummaries = this.betSlips.refund;
        this.betSlips.refund = [];
        // generate an array of betSlipIds
        const betSlipIds = betSlipSummaries.map(summary => summary.betSlipId);
        // toastr content
        const content: ContentInterface = {
            title: 'account.bets.slip.notification.refund',
            message: 'account.bets.slip.notification.more'
        };
        // show toastr
        this.toastrService.warning(content, { betSlips: betSlipIds.length }).then((toastr: ActiveToast<any>) => {
            toastr.onTap.pipe(take(1)).subscribe(() => this.modalService.openBetSlipsDetail(betSlipIds, 'refund'));
        });
    }

    /**
     * opens the best of modal (modal)
     * 
     * @param notification 
     */
    private handleBetSlipBestOfNotification(notification: NotificationInterface): ModalController {
        return this.modalService.open(new ModalBetSlipBestOf({
            eventDate: new Date(notification.parameters.eventDate),
            betSlipId: notification.parameters.betSlipId,
            ranking: notification.parameters.ranking,
            category: notification.parameters.category,
            bonus: {
                coins: notification.parameters.bonusCoins,
            }
        }));
    }

    /**
     * opens the ranking notification (modal)
     * 
     * @param notification 
     */
    private handleSeasonRankingNotification(notification: NotificationInterface): ModalController {
        return this.modalService.open(new ModalSeasonRanking({
            eventDate: new Date(notification.parameters.eventDate),
            ranking: notification.parameters.ranking,
            category: notification.parameters.category,
            bonus: {
                coins: notification.parameters.bonusCoins,
            }
        }));
    }

    /**
     * opens the season winladder update notification (modal)
     * 
     * @param notification 
     */
    private handleSeasonWinLadderUpdateNotification(notification: NotificationInterface): ModalController {
        return this.modalService.open(new ModalSeasonWinLadder({
            eventDate: new Date(notification.parameters.eventDate),
            winLadderId: notification.parameters.winLadderId,
            result: notification.parameters.result,
            queue:this.queuedNotificationsShared,

            bonus: {
                coins: notification.parameters.bonusCoins
            }
        }));
    }

    /**
     * opens the achievement complete modal
     * 
     * @param notification 
     */
    private handleAchievementNotification(notification: NotificationInterface): ModalController {
        return this.handleTaskComplete(
            'achievement',
            'achievement.complete.message',
            notification.parameters.title,
            notification.parameters.message,
            notification.parameters.level,
            {
                message: 'achievement.complete.bonus',
                xp: notification.parameters.bonusXp,
                coins: notification.parameters.bonusCoins,
                booster: notification.parameters.bonusBooster,
            }
        );
    }

    /**
     * opens the daiy mission complete modal
     * 
     * @param notification 
     */
    private handleDailyMissionNotification(notification: NotificationInterface): ModalController {
        return this.handleTaskComplete(
            'arena',
            notification.parameters.title,
            notification.parameters.message,
            null,
            null,
            {
                message: 'dailyMission.complete.bonus',
                xp: notification.parameters.bonusXp,
                coins: notification.parameters.bonusCoins,
                booster: notification.parameters.bonusBooster,
            }
        );
    }

    /**
     * opens a task complete modal designed for achievements and daily missions
     * 
     * @param taskType 
     * @param header 
     * @param title 
     * @param message 
     * @param level 
     * @param bonus 
     */
    private handleTaskComplete(taskType: 'arena' | 'achievement', header: string, title: string, message: string, level?: number, bonus?: { message?: string, xp?: number, coins?: number, booster?: number }): ModalController {
        return this.modalService.openTaskComplete({
            header: header,
            title: title,
            message: message,
            level: level || 0,
            bonus: {
                message: bonus?.message || '',
                xp: bonus?.xp || null,
                coins: bonus?.coins || null,
                booster: bonus?.booster || null,
            }
        }, taskType);
    }
}
