import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { TranslationService } from '../../translation';
import { ResponseInterface } from '../interface/response.interface';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient, private translationService: TranslationService) { }

  private createDefaultOptions(headers: Record<string, string | number | boolean>): Record<string, any> {

    return {
      withCredentials: true,
      headers: {
        "application-locale": this.translationService.getSelectedLocale()
          || "prefer " + this.translationService.getPreferableFallbackLocale(),
        ...headers
      }
    };
  }
  /**
   * get data from url
   *
   * @param url
   * @param parameter
   * @param fallback
   * @returns
   */
  public get<Type>(
    url: string,
    parameter?: HttpParams | { [key: string]: any },
    fallback?: Array<any> | { [key: string]: any } | null,
    headers?: Record<string, string | number>
  ): Observable<Type> {
    const options = this.createDefaultOptions(headers);
    options.params = parameter || {};
    options.withCredentials = true;
    const observable = this.http.get<Type>(url, options);
    return this.getRequestObservable<Type>(observable, fallback);
  }

  /**
   * post data to url
   *
   * @param {string} url - The url to send the request to.
   * @param {FormData | HttpParams | {[key: string]: any}} [data] - The data to be sent to the server.
   * @param {Array<any> | {[key: string]: any;} | null} [fallback] - This is the data that will be
   * returned if the request fails.
   * @param {boolean} [withCredentials] - boolean - If true, the request will be sent with credentials.
   * @returns An observable of type Type.
   */
  public post<Type>(
    url: string,
    data?: FormData | HttpParams | { [key: string]: any },
    fallback?: Array<any> | { [key: string]: any } | null,
    withCredentials: boolean = true,
    headers?: Record<string, string>
  ): Observable<Type> {
    const options = this.createDefaultOptions(headers);
    const observable = this.http.post<Type>(url, data || {}, options);
    return this.getRequestObservable<Type>(observable, fallback);
  }

  /**
   * send patch data to url
   *
   * @param url
   * @param data
   * @param fallback
   * @returns
   */
  public patch<Type>(
    url: string,
    data?: FormData | HttpParams | { [key: string]: any },
    fallback?: Array<any> | { [key: string]: any } | null,
    headers?: Record<string, string>
  ): Observable<Type> {
    const options = this.createDefaultOptions(headers);
    const observable = this.http.patch<Type>(url, data || {}, options);

    return this.getRequestObservable<Type>(observable, fallback);
  }

  /**
   * send delete request
   *
   * @param url
   * @param parameter
   * @param fallback
   * @returns
   */
  public delete<Type>(
    url: string,
    parameter?: HttpParams | { [key: string]: any },
    fallback?: Array<any> | { [key: string]: any } | null,
    headers?: Record<string, string>
  ): Observable<Type> {
    const options = this.createDefaultOptions(headers);
    options.params = parameter || {};
    const observable = this.http.delete<Type>(url, options);
    return this.getRequestObservable<Type>(observable, fallback);
  }

  /**
   * prepares the passed ovservable with the default
   * request pipes / filter
   *
   * @param toFilter
   * @param fallback
   * @returns
   */
  private getRequestObservable<Type>(
    toFilter: Observable<Type>,
    fallback?: Array<any> | { [key: string]: any } | null
  ): Observable<Type> {
    return toFilter.pipe(
      first(),
      catchError((error: any) => this.onHttpError(error, fallback)),
      map((response: ResponseInterface) => <Type>response.data)
    );
  }

  /**
   * If the fallback is defined, return an Observable with the fallback data, otherwise throw the
   * error
   *
   * @param {any} error - The error object that was thrown by the http request
   * @param {Array<any> | {[key: string]: any;} | null} [fallback] - This is the fallback data that
   * will be returned if the request fails.
   * @returns Observable<ResponseInterface>
   */
  private onHttpError(
    error: any,
    fallback?: Array<any> | { [key: string]: any } | null
  ): Observable<ResponseInterface> {
    if (typeof fallback !== 'undefined') {
      return of({
        http_code: HttpStatusCode.Unused,
        success: false,
        status: 'error',
        data: fallback,
      });
    }
    // just throw the error again
    return throwError(error);
  }
}
