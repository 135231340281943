import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ResetPasswordService } from '../../../../core/member';
import { FromHelper } from '../../../../../shared/form/helper/form-helper';
import { ModalController } from '../../model/modal-controller.model';
import { ModalEventType } from '../../model/modal-event-type.model';
import { Modal } from '../../model/modal.model';
import { ModalComponent } from '../modal/modal.component';
import { LabelService } from '../../../../core/label';

@Component({
  selector: 'modal-password',
  templateUrl: './modal-password.component.html',
  styleUrls: ['./modal-password.component.scss'],
})
export class ModalPasswordResetComponent extends ModalComponent {
  /**
   * register formular
   */
  public passwordResetFormular: UntypedFormGroup;

  /**
   * error string to display in modal on
   * validation error
   */
  public error: string;

  /**
   * while we wait for the registration response
   * isLoading is true otherwise false
   */
  public isLoading: boolean;

  /**
   * inject dependencies
   *
   * @param formBuilder
   * @param bsModalService
   * @param modalController
   * @param modal
   * @param passwordResetService
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected bsModalService: BsModalService,
    protected modalController: ModalController,
    protected modal: Modal,
    protected passwordResetService: ResetPasswordService,
    protected labelService: LabelService
  ) {
    super(bsModalService, modalController, modal);
  }

  public ngOnInit(): void {
    this.buildFormular();
  }

  /**
   * on submit password reset formular
   */
  public onSubmit(): boolean {
    if (this.passwordResetFormular.invalid) {
      this.handleFormErrors();
      return false;
    }
    this.sendResetRequest();
    return false;
  }

  public logoPath() {
    return this.labelService.getCurrentLogoPath().additional;
  }

  /**
   * builds the formular
   */
  private buildFormular(): void {
    this.passwordResetFormular = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  /**
   * try to request a password forget mail
   */
  private sendResetRequest(): void {
    this.isLoading = true;
    this.passwordResetService.request(this.fc.email.value).subscribe({
      next: () => {
        this.isLoading = false;
        this.error = '';
        this.passwordResetFormular.reset();
        this.modalController.sendToParent(ModalEventType.Success, {
          title: 'reset-password.request.success.title',
          message: 'reset-password.request.success.message',
        });
        this.close();
      },
      error: (e) => {
        this.isLoading = false;
        this.error = typeof e.error === 'string' ? e.error : e.error.data || '';
      },
    });
  }

  /**
   * handles form errors
   */
  private handleFormErrors(): void {
    let errors: Array<any> = FromHelper.getFormValidationErrorsArray(
      this.passwordResetFormular
    );
    for (let i: number = 0; i < errors.length; i++) {
      this.error =
        'reset-password.request.error.' +
        errors[i].control +
        '.' +
        errors[i].error;
      break;
    }
  }

  /**
   * shortcut to use form contols
   */
  get fc(): any {
    return this.passwordResetFormular.controls;
  }
}
