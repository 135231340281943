import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input[type="number"]',
})
export class InputDirective {
  readonly regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|\.|ArrowRight)/);

  /**
   * 
   * Fix for Firefox behaviour => writing chars in input of numbers.
   */
  @HostListener('keydown', ['$event'])
  down(e: KeyboardEvent) {
    console.log(e);
    //@ts-ignore for e.target.value
    //                        ^^^^^
    ((!e.key.match(this.regex))|| (e.target.value.indexOf(".") > -1 && e.key == ".")) && e.preventDefault()
  }
  constructor() { }

}
