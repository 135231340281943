import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from './pipe/date.pipe';
import { DecimalPipe } from './pipe/decimal.pipe';
import { LanguageSelectorComponent } from './component/language-selector/language-selector.component';


@NgModule({
  declarations: [
    DatePipe,
    DecimalPipe,
    LanguageSelectorComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DatePipe,
    DecimalPipe,
    LanguageSelectorComponent
  ]
})
export class TranslationModule { }
