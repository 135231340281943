<ng-template #modal>
  <div class="modal-content">
    <div class="modal-body">
      <carousel [noWrap]="true" [isAnimated]="false" [interval]="50000" (activeSlideChange)="onSlideChange($event)">
        <slide *ngFor="let slide of slides; let first = first">
          <img [src]="slide.image" [alt]="slide.alt" style="display: block; width: 100%"/>
          <ng-template [ngIf]="first" [ngIfElse]="notFirst">
            <div class="tutorial-title">
              <div class="tutorial-title__primary">{{ slide.title | translate }}</div>
              <div class="tutorial-title__default"
                [style.font-size]="calculateFontSize() + 'px'">
                {{ slide.url | translate }}
              </div>
            </div>
            <div class="tutorial-description">
              <div class="tutorial-description__default font-40">
                {{ slide.description | translate }}
              </div>
              <div class="tutorial-description__primary">
                {{ slide.additionalDesc | translate }}
              </div>
            </div>
          </ng-template>

          <ng-template #notFirst>
            <div class="tutorial-description">
              <div class="tutorial-description__default font-40">
                {{ slide.description | translate }}
              </div>
            </div>
          </ng-template>
        </slide>
      </carousel>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modalDismiss()">
        {{ (isAtEnd ? "close" : "skip") | translate }}
      </button>
    </div>
  </div>
</ng-template>
