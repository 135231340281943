import { MemberService } from "../service/member.service";

/**
 * provides an initializer which tries to auth and load the
 * member from api.
 *
 * @param memberService
 */
export function MemberInitializer(memberService: MemberService) {
    return () =>
        new Promise<void>((resolve) => {
            // attempt to refresh token on app start and load member data
            memberService
                .loginRefresh()
                .subscribe({ error: (e) => {} })
                .add(resolve);
        });
}
