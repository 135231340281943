import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CoreModule as FrontCoreModule } from "../front/core/core.module";
import { ConnectionInterceptor } from "./api";
import { AuthenticationInterceptor, SslInitializer } from "./security";
import { MemberInitializer, MemberService } from "./member";
import { TranslationInitializer, TranslationService } from "./translation";
import { WebSocketInitializer } from "./websocket/provider/web-socket.initializer";
import { WinLadderInitializer, WinLadderService } from "./winladder";
import { SslService } from "./security/service/ssl.service";
import { WebSocketService } from "./websocket";

@NgModule({
    declarations: [],
    exports: [],
    imports: [CommonModule, HttpClientModule, FrontCoreModule],
    providers: [
        // connection provider
        { provide: HTTP_INTERCEPTORS, useClass: ConnectionInterceptor, multi: true },
        // auth provider (intercept to sign with token)
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        // ssl initalizer (we redirect to https if its not used)
        { provide: APP_INITIALIZER, useFactory: SslInitializer, multi: true, deps: [SslService] },
        // api server connection (test if the connection to the server was successful)
        //{ provide: APP_INITIALIZER, useFactory: ConnectionInitializer, multi: true, deps: [ConnectionService] },
        // member provider
        {
            provide: APP_INITIALIZER,
            useFactory: TranslationInitializer,
            multi: true,
            deps: [TranslationService, MemberService],
        },
        { provide: APP_INITIALIZER, useFactory: MemberInitializer, multi: true, deps: [MemberService] },
        // translation provider
        // WebSocket provider
        { provide: APP_INITIALIZER, useFactory: WebSocketInitializer, multi: true, deps: [WebSocketService] },
        // winladder provider
        { provide: APP_INITIALIZER, useFactory: WinLadderInitializer, multi: true, deps: [WinLadderService] },
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error("CoreModule has already been loaded. Import CoreModule modules in root only.");
        }
    }
}
