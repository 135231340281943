import { environment } from "../../../../../../environments/environment";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { KeyValue } from "@angular/common";
import { Subscription } from "rxjs";
import { filter, first } from "rxjs/operators";
import {
    BetSlipCombinationService,
    BetSlipInterface,
    BetSlipModeEnum,
    BetSlipService,
    OfferInterface,
} from "../../../../core/bet";
import { SeasonInterface, SeasonService } from "../../../../core/season";
import { countdownReducer } from "../../../../../shared/datetime";
import { ModalController, ModalEventType, ModalService } from "../../../../shared/modal";
import { ToastrService } from "../../../../../shared/toastr";
import { MemberService } from "../../../../core/member";
import moment from "moment";
import { Router } from "@angular/router";

@Component({
    selector: "app-bet-betslip-logic",
    templateUrl: "./logic.component.html",
    styleUrls: ["./logic.component.scss"],
})
export class LogicComponent implements OnInit, OnDestroy {
    /**
     * current season
     */
    public season: SeasonInterface;

    /**
     * used to set translations well (day, days)
     */
    public daysLeft: number;

    /**
     * members current bet slip
     */
    public betSlip: BetSlipInterface;

    /**
     * all known bet slip modes
     */
    public betSlipMode = BetSlipModeEnum;

    /**
     * tracks if the user clicked button to decrease or increase bet slip coins
     */
    private buttonCoinClick: boolean = false;

    /**
     * tracks if the current bet slip is submitted
     */
    private isSubmitted: boolean = false;

    /**
     * subscription for bet slip data
     */
    private betSlipSubscription: Subscription;

    /**
     * season subscription
     */
    private seasonSubscriber: Subscription;

    /**
     * timeout before we have to update days left
     */
    private daysLeftTimeout: ReturnType<typeof setTimeout> | null;

    /**
     * add a fix to make sure count down day is reduces, so that
     * the day will count down to 0 not to 1s
     */
    public dateFormatReduceFix = countdownReducer;

    /**
     * bet slip container used to scroll down after a new bet
     * was placed (desktop)
     */
    private readonly betSlipScrollElementDesktopId = "betSlipScrollerDesktop";

    /**
     * bet slip container used to scroll down after a new bet
     * was placed (mobile)
     */
    private readonly betSlipScrollElementMobileId = "betSlipScrollerMobile";

    constructor(
        protected toastrService: ToastrService,
        protected modalService: ModalService,
        protected betSlipService: BetSlipService,
        protected betSlipCombiationService: BetSlipCombinationService,
        protected seasonService: SeasonService,
        protected memberService: MemberService,
        protected router:Router
    ) {
        this.daysLeftTimeout = null;
        this.daysLeft = 0;
    }

    public ngOnInit(): void {
        this.subscribeBetSlip();
        this.subscribeSeason();
    }

    public ngOnDestroy(): void {
        this.unsubscribeBetSlip();
        this.unsubscribeSeason();
    }

    /**
     * if the member manually changes the amount of coins for a bet
     * or the bet slip this eventlistener will take care of the amount
     */
    public onCoinChange(event: any, offer?: OfferInterface): void {
        const coins = isNaN(event.target.valueAsNumber)
            ? environment.betSlip.coinsMin
            : event.target.valueAsNumber;
        this.betSlipService.setCoins(coins, offer);
    }

    /**
     * increase the amount of coins via the '+' button
     *
     * @returns
     */
    public onCoinIncrease(offer?: OfferInterface): boolean {
      this.buttonCoinClick = true;
      this.betSlipService.addCoins(environment.betSlip.coinSteps, offer);
        return false;
    }

    /**
     * decrease the amount of coins via the '-' button
     *
     * @returns
     */
    public onCoinDecrease(offer?: OfferInterface): boolean {
        this.buttonCoinClick = true;
        this.betSlipService.addCoins(environment.betSlip.coinSteps * -1, offer);
        return false;
    }

    /**
     * returns the maximum coin win for this kind of bet
     */
    public getCoinWinLimit(): number {
        const limitations = environment.betSlip.bets[this.betSlip.mode.toLocaleLowerCase()];
        return limitations ? limitations.coinWinMax : 0;
    }

    /**
     * checks if the maximum win is reached
     * and returns false if so
     */
    public isCoinWinInRange(): boolean {
        const limit = this.getCoinWinLimit();
        return !limit || this.betSlip.win.coins < limit;
    }

    /**
     * if the system option was changed this will handle the new value
     *
     * @param event
     */
    public onSystemChange(event: any): void {
        const selected = +event.target.value;
        this.betSlipService.setSystemOption(selected);
    }

    /**
     * returns all allowed system bet options and the amount
     * of combinations
     *
     * @returns
     */
    public getSelectableSystemBet(): { [key: number]: number } {
        return this.betSlipCombiationService.getCombinationOptions(this.betSlip.count);
    }

    /**
     * check if the option is selected
     *
     * @param option
     * @returns
     */
    public isSystemBetSelected(option: number): boolean {
        return this.betSlip.system === +option;
    }

    /**
     * returns additional css classes from the submit button
     *
     * @returns
     */
    public getSubmitButtonCss(): string[] {
        const css = [];
        if (this.isSubmitDisabled()) {
            css.push("disabled");
        }
        return css;
    }

    /**
     * returns true if the submit button is disabled
     *
     * @returns
     */
    public isSubmitDisabled(): boolean {
        return this.betSlip.count === 0 || this.isSubmitted || !this.isCoinWinInRange();
    }

    /**
     * submits the bet slip (place the bet(s))
     *
     * @returns
     */
    public onSubmit(): boolean {
        if (this.isSubmitDisabled()) {
            return false;
        }

        this.isSubmitted = true;
        const modalController = this.modalService.openLoading("bet.place.loading");
        this.betSlipService.submit().subscribe({
            next: (betSlip: BetSlipInterface) => {
                this.isSubmitted = false;
                this.handleSubmitSuccess(modalController);
            },
            error: (response: any) => {
                this.isSubmitted = false;
                this.handleSubmitError(response, modalController);
            },
        });
        return false;
    }

    /**
     * removes a single bet by offer
     *
     * @param offer
     * @returns
     */
    public remove(offer: OfferInterface): boolean {
        this.buttonCoinClick = true;
        this.betSlipService.remove(offer);
        return false;
    }

    /**
     * removes all bets from bet slip
     *
     * @returns
     */
    public removeAll(): boolean {
        this.betSlipService.removeAll();
        return false;
    }

    /**
     * set the bet slip to passed mode (if allowed)
     *
     * @param mode
     * @returns
     */
    public setMode(mode: BetSlipModeEnum): boolean {
        this.betSlipService.setMode(mode);
        return false;
    }

    /**
     * returns css classed depending on allowed and
     * selected modes
     *
     * @param mode
     * @returns
     */
    public getModeCss(mode: BetSlipModeEnum): string[] {
        if (!this.betSlipService.isModeAvailable(mode)) {
            return ["disabled", "cursor-default"];
        }
        if (this.betSlip.mode === mode) {
            return ["active"];
        }
    }

    /**
     * return additional betSlip classes
     * depending on the bet count
     *
     * @returns
     */
    public getBetSlipClass(): string {
        return this.betSlip.count === 0 ? "empty" : "";
    }

    /**
     * returns the amount of bets
     * the betslip will result in
     *
     * @returns
     */
    public getBetCount(): number {
        return this.betSlip.mode === BetSlipModeEnum.System
            ? this.betSlipCombiationService.getCombinationCount(this.betSlip.count, this.betSlip.system)
            : this.betSlip.count;
    }

    /**
     * checks if the offer name indicates the default offers
     * (win/draw/lose)
     *
     * @param offerName
     * @returns
     */
    public isDefaultOffer(offerName: string): boolean {
        return offerName === "1" || offerName === "X" || offerName === "2";
    }

    /**
     * check if its a translateable offer detaiö
     *
     * @param offerName
     * @returns
     */
    public isTranslateableOfferDetail(offerName: string): boolean {
        return offerName === "Yes" || offerName === "No" || offerName === "Over" || offerName === "Under";
    }

    /**
     * scrolls the betslip container to the bottom
     */
    public scrollToBottom(): void {
        this.scrollContainerToBottom(this.betSlipScrollElementDesktopId);
        this.scrollContainerToBottom(this.betSlipScrollElementMobileId);
    }

    /**
     * focus the coin input field
     */
    public focusCoinInput(id: string): void {
        try {
            const inputElement = document.getElementById(id);
            inputElement.focus();
        } catch (e) {}
    }

    /**
     * just a little helper which focus the coin input field
     */
    public selectCoinInput(id: string): void {
        try {
            const inputElement = document.getElementById(id);
            //@ts-ignore
            // need to ignore that, because angular dont know
            // the select method
            inputElement.select();
        } catch (e) {}
    }

    /**
     * focus and select the coin input field
     */
    public focusAndSelectCoinInput(id: string): void {
        this.focusCoinInput(id);
        this.selectCoinInput(id);
    }

    /**
     * order by the time the bet / offer was added
     *
     * @param a
     * @param b
     * @returns
     */
    public orderAdded(a: KeyValue<number, any>, b: KeyValue<number, any>): number {
        return a.value.added === b.value.added ? 0 : a.value.added > b.value.added ? 1 : -1;
    }

    /**
     * keep the origin order
     *
     * @param a
     * @param b
     * @returns
     */
    public orderOrigin(a: KeyValue<number, any>, b: KeyValue<number, any>): number {
        return 0;
    }

    /**
     * handles bet slip submit success by closing the loading modal and
     * open a modal with will recirect to users bet overview
     */
    protected handleSubmitSuccess(loadingController: ModalController): void {
        loadingController
            .onModalEvent(ModalEventType.Closed)
            .pipe(first())
            .subscribe(() =>
                this.modalService
                    .openDefault({
                        title: "bet.place.success.title",
                        message: "bet.place.success.message",
                        buttonConfirm: "bet.place.success.button",
                        redirectTo: "/account/bets",
                    })
                    .onModalEvent(ModalEventType.Closed)
                    .pipe(first())
                    .subscribe(() => {
                        this.memberService.reload().subscribe();
                    })
            );
        loadingController.closeModal({ delay: 250 });
    }

    /**
     * handles errors which can occure when a betslip
     * becomes submitted
     *
     * @param response
     * @param loadingController
     */
    protected handleSubmitError(response: any, loadingController: ModalController): void {
        const error = response.error?.data?.error || response.message || "unexpected";
        switch (error) {
            // user is not logged in, so we open a modal to tell him to login or register
            case "Token expired or invalid":
                loadingController
                    .onModalEvent(ModalEventType.Closed)
                    .pipe(first())
                    .subscribe(() => {
                        this.router.navigate([""])
                    });
                loadingController.closeModal({ delay: 250 });
                break;

            // we corrected something on the bet slip, so we just close the modal and let the new
            // bet slip auto notify about the changes
            case "corrected":
                loadingController.closeModal({ delay: 250 });
                break;

            // server answert with invalid (should be very rare), so we refresh the bet slip
            // and close the loading modal after that and let the new bet slip notify the
            // member about the changes
            case "invalid":
                this.betSlipService.refresh().subscribe(() => {
                    loadingController.closeModal({ delay: 250 });
                });
                break;

            // if the member has not enough coins to place the bet slip
            // we open a "go to coin shop" modal
            case "coins":
                loadingController
                    .onModalEvent(ModalEventType.Closed)
                    .pipe(first())
                    .subscribe(() =>
                        this.modalService.openDefault({
                            title: "bet.error.coins.title",
                            message: "bet.error.coins.message",
                            note: "bet.error.coins.tip",
                            buttonConfirm: "bet.error.coins.button",
                            redirectTo: "/shop/coins",
                        })
                    );
                loadingController.closeModal({ delay: 250 });
                break;

            // any other error is "unexpected"... So we open a modal
            // which tell the user to try again later
            default:
                loadingController
                    .onModalEvent(ModalEventType.Closed)
                    .pipe(first())
                    .subscribe(() => this.modalService.openError("bet.error.unexpected"));
                loadingController.closeModal({ delay: 250 });
                break;
        }
    }

    /**
     * displays all messages added to the betslip via toastrService
     *
     * @param messages
     */
    private displayMessages(
        messages: Array<{
            message: string;
            parameter?: { [key: string]: string | number };
        }>
    ): void {
        for (const msg of messages) {
            this.toastrService.warning({ message: msg.message }, msg.parameter);
        }
    }

    /**
     * scrolls the container to the bottom
     *
     * @param containerId
     * @returns
     */
    private scrollContainerToBottom(containerId: string): void {
        const container = document.getElementById(containerId);
        if (!container) {
            return;
        }
        setTimeout(() => {
            try {
                container.scrollTop = container.scrollHeight;
            } catch (err) {}
        }, 50);
    }

    /**
     * subscribe bet slip data
     */
    private subscribeBetSlip(): void {
        this.betSlipSubscription = this.betSlipService
            .getBetSlipObservable()
            .subscribe((betSlip: BetSlipInterface) => {
              this.betSlip = betSlip;
              if (!this.buttonCoinClick) {
                this.scrollToBottom();
              }
              this.buttonCoinClick = false;
              this.displayMessages(betSlip.messages);
            });
    }

    /**
     * unsunscribe bet slip data
     */
    private unsubscribeBetSlip(): void {
        this.betSlipSubscription.unsubscribe();
    }

    /**
     * subscribe season and start day countdown
     */
    public subscribeSeason(): void {
        this.seasonSubscriber = this.seasonService
            .getSeasonObeservable()
            .pipe(filter((season: SeasonInterface) => season !== null))
            .subscribe((season: SeasonInterface) => {
                this.season = season;
                this.updateDaysLeft();
            });
    }

    /**
     * unsubscribe season and clear the day update timeout
     */
    public unsubscribeSeason(): void {
        this.seasonSubscriber.unsubscribe();
        this.clearDaysLeftTimeout();
    }

    /**
     * update days left for a better translation (day, days)
     */
    private updateDaysLeft(): void {
        // set days left
        const durationDiff = moment.duration(moment(this.season.endDate).diff(moment()));
        this.daysLeft = Math.floor(durationDiff.asDays());

        // calculate timeout for the next update
        const daysAsMs = this.daysLeft * 24 * 3600 * 1000;
        const timeout = durationDiff.asMilliseconds() - daysAsMs;
        // clear timeout and start a new one
        this.clearDaysLeftTimeout();
        setInterval(() => {
            this.updateDaysLeft();
        }, timeout);
    }

    /**
     * clears the daysLeftTimeout if set
     */
    private clearDaysLeftTimeout(): void {
        if (this.daysLeftTimeout !== null) {
            clearTimeout(this.daysLeftTimeout);
            this.daysLeftTimeout = null;
        }
    }
}
