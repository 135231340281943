import { Component } from '@angular/core';
import {DomSanitizer,SafeResourceUrl} from '@angular/platform-browser';
import { MemberInterface, MemberService } from "../../../../core/member";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ResetPasswordService } from '../../../../core/member';
import { FromHelper } from '../../../../../shared/form/helper/form-helper';
import { ModalController } from '../../model/modal-controller.model';
import { ModalEventType } from '../../model/modal-event-type.model';
import { Modal } from '../../model/modal.model';
import { ModalComponent } from '../modal/modal.component';
import { LabelService } from '../../../../core/label';
import { MinigameService } from 'src/app/front/page/quickgames/services/minigames.service';
@Component({
  selector: 'app-modal-minigames',
  templateUrl: './modal-minigames.component.html',
  styleUrls: ['./modal-minigames.component.scss']
})
export class ModalMinigamesComponent extends ModalComponent {
  //gameUrlx:SafeResourceUrl;
  minigame: any; 
  constructor(
    private sanatizer : DomSanitizer,
    private formBuilder: UntypedFormBuilder,
    protected bsModalService: BsModalService,
    protected memberService: MemberService,
    protected modalController: ModalController,
    protected modal: Modal,
    protected passwordResetService: ResetPasswordService,
    protected labelService: LabelService,
    private minigameService: MinigameService
  ) {
    super(bsModalService, modalController, modal);
  }

  public ngOnInit(): void {
  }


}
