import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { fromEventPattern, Observable, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NotificationHandlerService } from '../service/notification-handler.service';
import { NotificationInterface } from '../interface/notification.interface';
import { MemberInterface, MemberService } from '../../member';
import { NotificationLoaderService } from './notification-loader.service';
import { ConnectionService } from '../../../../core/api';

@Injectable({
    providedIn: 'root'
})
export class NotificationListenerService {
    /**
     * the amount of ticks to reach before the
     * member is considered inactive (each tick
     * are 10 seconds (by default). 6 ticks = 1min)
     */
    private readonly inactiveTickMax: number = 12;

    /**
     * duration for a single tick in ms (10 secs by default)
     */
    private readonly inactiveTickDuration: number = 10000;

    /**
     * loads the unread messages each loadingInterval ms (5 secs by default)
     */
    private readonly loadingInverval: number = 5000;

    /**
     * will be increased on each inactive check
     */
    private inactiveTickCount: number;

    /**
     * indicates if the inactivity state of a member
     */
    private isInactive: boolean;

    /**
     * the interval that load user notifications
     */
    private notificationLoadInterval: ReturnType<typeof setTimeout> | null = null;

    /**
     * notification data subscription
     */
    private notificationSubscription: Subscription;

    /**
     * member subscription
     */
    private memberSubscription: Subscription;    

    /**
     * a renderer instance we use to listen on mouse movement
     */
    private renderer: Renderer2;

    /**
     * we listen for new notifications until the subject
     * is changed
     */
    private hasStopped: Subject<boolean>;

    /**
     * will push true if there are new notifications
     * received from the api
     */
    private hasReceivedNotifiactions: Subject<boolean>;

    /**
     * the connection state
     */
    private isConnected: boolean;

    /**
     * inject and create dependencies
     * 
     * @param rendererFactory2 
     * @param notificationLoaderService 
     * @param modalService 
     * @param toastrService 
     */
    public constructor(
        private rendererFactory2: RendererFactory2,
        private connectionService: ConnectionService,
        private notificationLoaderService: NotificationLoaderService,
        private notificationHandlerService: NotificationHandlerService,
        private memberService: MemberService
    ) {
        this.hasStopped = new Subject<boolean>();
        this.hasReceivedNotifiactions = new Subject<boolean>();
        this.renderer = this.rendererFactory2.createRenderer(null, null);
        this.connectionService.getStatusObservable().subscribe((connected: boolean) => {
            this.isConnected = connected;
        });
    }

    /**
     * starts to listen on new notifications
     */
    public start(): void {
        this.resetInactivity();
        this.startInactivityCheck();
        this.subscribeMember();
        this.subscribeNotifications();
        this.addEventListener();
        this.startNotificationLoading();
    }

    /**
     * stops to listen on new notifications
     */
    public stop(): void {
        this.unsubscribeMember();
        this.unsubscribeNotifications();
        this.stopNotificationLoading();
        this.stopInactivityCheck();
        this.removEventListener();
    }

    /**
     * if the listener received new notifications the
     * observable will push true
     * 
     * @returns 
     */
    public getHasReceivedNotificationsObservable(): Observable<boolean> {
        return this.hasReceivedNotifiactions.asObservable();
    }

    /**
     * add all event listener to reset inactivity
     */
    private addEventListener(): void {
        //this.createEventListener('mousemove');
        this.createEventListener('click');
        this.createEventListener('scroll');
    }

    /**
     * adds an eventlistener to document to reset the inactivity
     * 
     * @param event 
     */
    private createEventListener(event: string): void {
        // the listener will be set by the formEventPattern,
        // and we need to call it, to remove the listener
        // from the document again
        let eventListener: () => void;
        // create event pattern...
        fromEventPattern<Event>(
            // add the listener to the document and stores the listener
            (handler: (e: Event) => boolean | void) => eventListener = this.renderer.listen("document", event, this.resetInactivity.bind(this)),
            // use the stored listener to remove it
            () => eventListener()
        // start to fetch the events until the listener is stopped
        ).pipe(takeUntil(this.hasStopped)).subscribe();        
    }

    /**
     * we remove the listener by set and complete
     * the isDestoryed subject
     */
    private removEventListener(): void {
        this.hasStopped.next(true);
        this.hasStopped.complete();
    }

    /**
     * increase the inactivity tick count every 10 seconds to check
     * if the member is inactive. If the count reaches the max the 
     * user havnt moved the mouse a while so we set isInactive to
     * true
     */
    private startInactivityCheck(): void {
        setInterval(() => {
            // if the user is inactive we dont need to do anything
            if (this.isInactive) {
                return;
            }
            // increase the tick count
            this.inactiveTickCount++;
            // and if the max is reached set inactive
            if (this.inactiveTickCount >= this.inactiveTickMax) {
                this.isInactive = true;
            }
        }, this.inactiveTickDuration);
    }

    /**
     * stops the inactivity check
     */
    private stopInactivityCheck(): void {
        if (this.notificationLoadInterval !== null) {
            clearInterval(this.notificationLoadInterval);
        }        
    }

    /**
     * resets the tick count to 0
     */
    private resetInactivity(event?: any): boolean | void {
        this.isInactive = false;
        this.inactiveTickCount = 0;
    }

    /**
     * starts to load notifications in an interval of
     * 10 seconds (if not inactive) with a short delay
     * to avoid that messages becomes loaded before
     * the page is completly loaded and displayed
     */
    private startNotificationLoading(): void {
        setTimeout(() => {
            this.setNotificationLoading();
        }, this.loadingInverval);
    }

    /**
     * set / starts the loading interval
     */
    private setNotificationLoading(): void {
        this.stopNotificationLoading();
        this.notificationLoadInterval = setInterval(() => {
            if (!this.isInactive && this.isConnected) {
                this.notificationLoaderService.loadUnreadNotifications().subscribe();
            }
        }, this.loadingInverval);
    }

    /**
     * stops the loading interval
     */
    private stopNotificationLoading(): void {
        if (this.notificationLoadInterval !== null) {
            clearInterval(this.notificationLoadInterval);
            this.notificationLoadInterval = null;
        }
    }

    /**
     * observe for new notifications
     */
     private subscribeNotifications(): void {
        this.notificationSubscription = this.notificationLoaderService.getUnreadNotificationsObservable()
            .pipe(filter((notifications: NotificationInterface[]) => notifications !== null))
            .subscribe((notifications: NotificationInterface[]) => {
                if(notifications.length > 0){
                    this.notificationHandlerService.queue(notifications);
                    this.notificationLoaderService.clearUnread();
                    this.hasReceivedNotifiactions.next(true);
                }
            });
    }

    /**
     * unsubscribe unread notifications
     */
    private unsubscribeNotifications(): void {
        this.notificationSubscription.unsubscribe();
    }    
    
    /**
     * each time the member becomes loaded/reloaded we will also reload
     * the unread notifications of the member
     */
    private subscribeMember(): void {
        this.memberSubscription = this.memberService.getMemberChangeObservable().pipe(
            filter((member: MemberInterface) => member !== null)
        ).subscribe((data: MemberInterface) => {
            this.notificationLoaderService.loadUnreadNotifications().subscribe();
        });
    }

    /**
     * unsubscribe member changed
     */
    private unsubscribeMember(): void {
        this.memberSubscription.unsubscribe();
    }    
}
