import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    Type,
} from "@angular/core";
import { first } from "rxjs/operators";
import { ModalParameterInterface, ModalTextInterface } from "..";
import { BoosterTypeInterface } from "../../../core/booster";
import { BetSlipDetailInterface, MemberService } from "../../../core/member";
import { CoinPackageInterface } from "../../../core/shop";
import { ModalInjector } from "../injector/modal-injector";
import { ModalEventInterface } from "../interface/modal-event.interface";
import { ModalInterface } from "../interface/modal.interface";
import { ModalController } from "../model/modal-controller.model";
import { ModalEventType } from "../model/modal-event-type.model";
import { ModalTypeComponentsMap } from "../model/modal-type-components-map.model";
import { ModalType } from "../model/modal-type.model";
import {
    Modal,
    ModalBetSlip,
    ModalBetSlipDetail,
    ModalBetWins,
    ModalConfirm,
    ModalDefault,
    ModalError,
    ModalLoading,
    ModalLogin,
    ModalPasswordResetReset,
    ModalPayment,
    ModalRegister,
    ModalSuccess,
    ModalSystemBet,
    ModalTaskComplete,
    ModalVideoAds,
    ModalMinigames
} from "../model/modal.model";

@Injectable({
    providedIn: "root",
})
export class ModalService {
    /**
     * all modal components with its type as key
     */
    private readonly modalTypeComponentsMap = ModalTypeComponentsMap;

    /**
     * our modal storage
     */
    private modalStorage: { controller: ModalController; component: ComponentRef<any> }[] = [];

    /**
     * inject dependencies
     *
     * @param componentFactoryResolver to create components with
     * @param appRef a reference to the app
     * @param injector injector for DI on component creation
     */
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private memberService: MemberService
    ) {}

    /**
     * opens a modal by type
     *
     * @param modal
     * @returns
     */
    public open(modal: ModalInterface): ModalController {
        return this.get(modal).openModal();
    }

    /**
     * opens a default modal, in which you can configure the title (large
     * text as headline), the message (small text as body), the button(s),
     * an optional a redirect route for the button, etc...
     *
     * @param parameter
     * @returns
     */
    public openDefault(parameter?: ModalParameterInterface): ModalController {
        return this.open(new ModalDefault(parameter));
    }

    /**
     * opens a success modal, in which you can configure the title (large
     * text as headline), the message (small text as body), the button(s),
     * an optional a redirect route for the button, etc...
     *
     * @param parameter
     * @returns
     */
    public openSuccess(parameter?: string | ModalParameterInterface): ModalController {
        // create modal parameter from string or use the original parameter
        const modalParameter = typeof parameter === "string" ? { title: parameter } : parameter;
        return this.open(new ModalSuccess({ icon: "fa-check-circle", ...modalParameter }));
    }

    /**
     * opens an error, in which you can configure the title (large
     * text as headline), the message (small text as body), the button(s),
     * an optional a redirect route for the button, etc...
     *
     * @param parameter
     * @returns
     */
    public openError(parameter?: string | ModalParameterInterface): ModalController {
        // create modal parameter from string or use the original parameter
        const modalParameter =
            typeof parameter === "string" || typeof parameter === "undefined"
                ? { message: parameter, title: "modal.error.title" }
                : parameter;
        return this.open(new ModalError(modalParameter));
    }

    /**
     * opens a confirm modal, like the default modal but with two buttons
     * and event callback for the user decision
     *
     * @param parameter
     * @returns
     */
     public openConfirm(parameter?: ModalParameterInterface): ModalController {
        return this.open(new ModalConfirm(parameter));
    }    

    /**
     * If there was a previous login, open the login modal,
     * otherwise open the register modal
     * 
     * @returns
     */
    public openLoginOrRegister(): ModalController {
        // if there was an previous login we open the login modal
        // or the register modal if there is none
        return (this.memberService.hasPreviousLogin())
            ? this.openLogin()
            : this.openRegister();
    }

    /**
     * open the login modal
     *
     * @returns
     */
    public openLogin(): ModalController {
        return this.open(new ModalLogin());
    }

    /**
     * open the register modal
     *
     * @returns
     */
    public openRegister(): ModalController {
        return this.open(new ModalRegister());
    }

    /**
     * open the password reset modal
     *
     * @returns
     */
    public openPasswordReset(): ModalController {
        return this.open(new ModalPasswordResetReset());
    }

    public openMinigames(): ModalController {
        return this.open(new ModalMinigames());
    }
    /**
     * opens the payment modal, which will close all
     * currently open payment modals.
     *
     * @param offer
     */
    public openPayment(offer: CoinPackageInterface | BoosterTypeInterface): ModalController {
        return this.open(new ModalPayment(offer));
    }

    /**
     * opens the bet win modal, wich will tell the member that he has won
     * at least one bet slip. With the button the user will open the
     * bet slips as overview.
     *
     * @param betSlipSummaries
     * @returns
     */
    public openBetWins(
        betSlipSummaries: Array<{ betSlipId: number; coinsWin: number; xpWin: number }>
    ): ModalController {
        return this.open(new ModalBetWins(betSlipSummaries));
    }

    /**
     * opens the bet slip(s) detail modal, wich will load the bet slip(s) via api request (all bets)
     *
     * @param betSlipIds
     * @param status
     * @returns
     */
    public openBetSlipsDetail(
        betSlipIds: number | number[],
        status: "win" | "lose" | "refund" | "mixed" = "mixed"
    ): ModalController {
        return this.open(new ModalBetSlipDetail(betSlipIds, status));
    }

    /**
     * opens the current bet slip (where u can changes your bets, confirm them, etc)
     *
     * @returns
     */
    public openBetSlip(): ModalController {
        return this.open(new ModalBetSlip());
    }

    /**
     * opens the system bet detail modal
     *
     * @param betSlip
     * @returns
     */
    public openSystemBet(betSlip: BetSlipDetailInterface): ModalController {
        return this.open(new ModalSystemBet(betSlip));
    }

    /**
     * task complete used for achievements and daily missions
     *
     * @param parameter
     * @returns
     */
    public openTaskComplete(
        parameter?: {
            header: string | ModalTextInterface;
            title: string | ModalTextInterface;
            message: string | ModalTextInterface;
            note?: string | ModalTextInterface;
            bonus?: { message?: string; xp?: number; coins?: number; booster?: string | number };
            level?: number;
        },
        taskType: "arena" | "achievement" = "achievement"
    ) {
        return this.open(new ModalTaskComplete(parameter, taskType));
    }

    /**
     * opens the video ad modal, which will close all
     * currently open video ad modals.
     *
     * @returns
     */
    public openVideoAd(): ModalController {
        return this.open(new ModalVideoAds());
    }

    /**
     * opens an loading modal, which will close all
     * currently open loading modals. No message will
     * result in a loading animation only.
     *
     * @param message
     * @returns
     */
    public openLoading(message?: string | ModalTextInterface): ModalController {
        return this.open(new ModalLoading(message));
    }

    /**
     * close all modals
     */
    public closeAll(): void {
        this.modalStorage.forEach((entry, index) => {
            // just in case the entry no longer exist
            if (!entry) {
                return;
            }
            // close modal with controller
            entry.controller.closeModal();
        });
    }

    /**
     * close all modals by type
     *
     * @param modalType
     */
    public closeType(modalType: ModalType): void {
        this.modalStorage.forEach((entry, index) => {
            // just in case the entry no longer exist
            // or not the requested modalType
            if (!entry || entry.controller.getModalType() !== modalType) {
                return;
            }
            // close modal with controller
            entry.controller.closeModal();
        });
    }

    /**
     * returns a controller for the requested modal
     *
     * @param modal
     * @returns
     */
    public get(modal: ModalInterface): ModalController {
        const preProcecssedController = this.preProcessModalOptions(modal);
        if (preProcecssedController) {
            return preProcecssedController;
        }
        const controller = this.getController(modal);
        this.postProcessModalOptions(controller);
        return controller;
    }

    /**
     * returns a modal controller for the requested modal
     *
     * @param modal
     * @returns
     */
    private getController(modal: ModalInterface): ModalController {
        let modalController: ModalController = null;
        // if static we try to get an existing modal
        if (!modal.options || !modal.options.behavior || modal.options.behavior === "static") {
            modalController = this.reuse(modal.type);
        }
        // return modalController from storage or create a new one
        return modalController || this.create(modal);
    }

    /**
     * get all modal controller by type
     *
     * @param modalType
     */
    public getControllerByType(modalType: ModalType): Array<ModalController> {
        const controller: Array<ModalController> = [];
        this.modalStorage.forEach((entry, index) => {
            // just in case the entry no longer exist
            // or not the requested modalType
            if (!entry || entry.controller.getModalType() !== modalType) {
                return;
            }
            controller.push(entry.controller);
        });
        return controller;
    }

    /**
     * creates new modal from component, set it to storage and
     * returns the modal controller
     *
     * @param modal
     * @returns
     */
    private create(modal: ModalInterface): ModalController {
        // get component to use
        if (!this.modalTypeComponentsMap[modal.type]) {
            throw "Modal Type '" + modal.type + "' is not defined in service";
        }
        const component = this.modalTypeComponentsMap[modal.type];
        // generate a controller to inject into the modal
        // and also to return so it can be used everywhere
        const modalController = new ModalController(modal, this);
        const config = this.getComponentConfig(modal, modalController);

        // inject and configure component and store it
        const componentRef: ComponentRef<any> = this.injectComponent(component, config);
        this.modalStorage.push({ controller: modalController, component: componentRef });

        return modalController;
    }

    /**
     * search in modal storage for an information set and return its
     * controller if found
     *
     * @param modalType
     * @returns
     */
    private reuse(modalType: ModalType): ModalController | null {
        // try to get information from storage
        const modalEntry: { controller: ModalController; component: ComponentRef<any> } =
            this.modalStorage.find((entry) => {
                return entry.controller.getModalType() === modalType;
            });
        // return controller or null if moadlEntry is undefined
        return modalEntry ? modalEntry.controller : null;
    }

    /**
     * removes a modal by controller
     *
     * @param controller
     * @returns
     */
    private remove(controller: ModalController): void {
        // try to find index in storage
        const removeIndex = this.modalStorage.findIndex((entry: any) => entry.controller === controller);
        if (removeIndex < 0) {
            return;
        }
        // remove component and dependencies
        this.removeComponent(this.modalStorage[removeIndex].component);
        this.modalStorage.splice(removeIndex, 1);
    }

    /**
     * generates a weekmap and set modal and controller to it
     *
     * @param modal
     * @param controller
     * @returns
     */
    private getComponentConfig(modal: ModalInterface, controller: ModalController): any {
        // create config and assign the modal and controller to it
        const config = new WeakMap();
        config.set(Modal, modal);
        config.set(ModalController, controller);
        return config;
    }

    /**
     * injects component into application
     *
     * @param component
     * @param config
     * @returns
     */
    private injectComponent(component: Type<any>, config: any): ComponentRef<any> {
        // inject component into app
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = componentFactory.create(new ModalInjector(this.injector, config));
        this.appRef.attachView(componentRef.hostView);
        // incect component into DOM
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
        return componentRef;
    }

    /**
     * removes a modal component
     *
     * @param componentRef
     */
    private removeComponent(componentRef: ComponentRef<any>): void {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
    }

    /**
     * close open modals, etc
     *
     * @param modal
     * @returns
     */
    private preProcessModalOptions(modal: ModalInterface): ModalController | null {
        if (modal.options && modal.options.closeAll) {
            this.closeAll();
        }
        if (modal.options && modal.options.closeSame) {
            this.closeType(modal.type);
        }
        // this should not be moved so, that all modals can close before
        // we check if there is an open controller
        if (modal.options && modal.options.openUniqe) {
            return this.getControllerByType(modal.type)[0] || null;
        }
        return null;
    }

    /**
     * set remove on close etc.
     *
     * @param controller
     */
    private postProcessModalOptions(controller: ModalController): void {
        this.removeOnClosed(controller);
    }

    /**
     * remvoe the modal on closed
     *
     * @param controller
     */
    private removeOnClosed(controller: ModalController): void {
        // only subscribe if modal option is set to multi, so if not we return here
        if (!controller.getModalOptions() || controller.getModalOptions().behavior !== "multi") {
            return;
        }
        // subscribe to afterClosed event to remove the modal from dom and app
        controller
            .onModalEvent(ModalEventType.Closed)
            .pipe(first())
            .subscribe((evt: ModalEventInterface) => this.remove(controller));
    }
}
