<ng-template #modal>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalDismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <img
    [src]="logoPath()"
    class="mx-auto d-block img-fluid img-logo huge-icon"
  />
  <div class="modal-body pb-5">
    <form [formGroup]="passwordResetFormular" (submit)="onSubmit()">
      <div class="row">
        <div class="col-12 col-sm-10 mx-auto text-center">
          <h2 class="tigerfont text-uppercase mb-3">
            {{ "reset-password.request.title" | translate }}
          </h2>
        </div>
        <div class="col-12 col-sm-10 mx-auto text-center">
          <p>{{ "reset-password.request.description" | translate }}</p>
          <input
            type="text"
            formControlName="email"
            class="form-control mb-2 mt-3 text-center"
            placeholder="{{
              'reset-password.request.formular.email.placeholder' | translate
            }}"
          />
        </div>
        <div class="col-12 col-sm-11 mx-auto text-center">
          <button
            [disabled]="isLoading"
            type="submit"
            class="btn btn-primary btn-lg mx-auto text-uppercase tigerfont mt-4"
          >
            {{ "reset-password.request.formular.submit" | translate }}
          </button>
        </div>
      </div>
      <div *ngIf="error" class="mt-4 text-center">{{ error | translate }}</div>
    </form>
  </div>
</ng-template>
