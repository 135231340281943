/**
 * ATTENTION:
 * check you have setup all parameters like
 * in the environment.ts. If there is a
 * parameter missing, the application will
 * crash
 */
export const environment = {
  // environment information
  production: true,
  env: 'stage',
  version: '0.6.4',

  /**
   * default host
   */
  host: 'tigerking.com',

  /**
   * Default page title
   */
  title: 'Tigerking.com',

  /**
   * if true the app force a ssl
   * connection by it self
   */
  forceSSL: false,

  /**
   * Api settings
   */
  api: {
    // request: 'http://localhost:4200/api',
    // public: 'http://localhost:4200'
    // request: 'https://tigerking-stage.wundermachine.com/api',
    // public: 'https://tigerking-stage.wundermachine.com'
    requestImages: 'https://staging-2-api.tigerking.de',
    request: 'https://staging-2-api.tigerking.de/api',
    public: 'https://staging-2-www.tigerking.de',
  },

  /**
   * WebSocket connection settings
   */
  websocket: {
    url: 'wss://staging-2-api.tigerking.de',
    protocol: 'client_read_only',
  },

  /**
   * security settings
   */
  security: {
    // internal security key (do not use any server-side key!)
    key: 'QRELu7HsGuJSnHGsafGnssx2N52s9Lqr7avH4c7V9sdb9D2BAguAvPNSqVdL',
  },

  /**
   * cookie names to use to store some information.
   *
   * Note: If you change the names here, you have
   * to change the names also in the backend config
   */
  cookies: {
    // the affiliate cookie stores all information about
    // the affiliate data. Used for the registration.
    affiliate: 'affiliate',
    // the label cookie stores the short name of the
    // detected label
    label: 'label',
    // last login cookie to check for an previous login
    login: 'login',

    domain: "tigerking.de"
  },

  /**
   * translation settings
   * Note: en and de are included in the app (no lazy load), to change that modify the translation service
   */
  translation: {
    locales: ['de_DE', 'en_GB'],
    fallback: 'de_DE',
    localStorageKey: '_language',
  },

  /**
   * bet slip settings
   */
  betSlip: {
    coinsMin: 100,
    coinsMax: 99999999,
    coinSteps: 100,
    bets: {
      // combi bet behaviour
      combi: {
        // min amount of bets for this type of bet
        betsMin: 2,
        // max allowed bets (0 to disable)
        betsMax: 50,
        // maximum win in coins a combi bet
        // can payout. Amounts above will disable
        // the function to place the bet
        coinWinMax: 999999999999,
      },
      // system bet behaviour
      system: {
        // min amount of bets for this type of bet
        betsMin: 3,
        // max allowed system bets (0 to disable)
        betsMax: 40,
        // because some a system bet like (10/20)
        // would result in 184.756 combinaions
        // thats way to much - we need to limit
        // the max allowed combinations otherwise
        // its possible that we run into DB
        // problems while saving them
        combinationsMax: 1500,
        // maximum win in coins a system bet
        // can payout. Amounts above will disable
        // the function to place the bet
        coinWinMax: 999999999999,
      },
    },
  },

  /**
   * google data
   */
  google: {
    login: {
      clientId:
        '1043452695150-fb7pg1j8khhddaedpg5vijk40be77ub7.apps.googleusercontent.com',
      scope: 'profile email',
      isEnabled: true,

    },
    tagManager: {
      id: 'GTM-K2HZ34K',
      send_to: 'AW-717261497/zszMCMrLmLoBELmVgtYC',
    },
    ads: {
      id: 'AW-717261497',
    },
    analytics: {
      id: 'UA-145885621-2',
    },
  },

  /**
   * facebook login data
   * Note: Dont include the secret here!
   */
  facebook: {
    login: {
      appId: '447544579150059',
      scope: 'email',
      isEnabled: true,
    },
    pixel: {
      id: '1311574652367478',
    },
  },
  /**
   * PunNub
   * Note: Don't include the secret or publish key here
   */
  // punnub: {
  //     subscribeKey: 'sub-c-a75810a0-9781-11e9-a293-62d4500be10d'
  // }
};
