import { DecimalPipe as DecimalPipeAngular } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { TranslationService } from '../../../core/translation';


@Pipe({
    name: 'number'
})
export class DecimalPipe implements PipeTransform {
    constructor(
        private translationServive: TranslationService
    ) { }


    public transform(value: any, digits: string = null): any {
        // try to format the given number with the selected
        // language (received from the translation service)
        try {
            const ngPipe = new DecimalPipeAngular(this.translationServive.getSelectedLocale());
            return ngPipe.transform(value, digits);
        }
        // if there was an error while formatting the number
        // in the current language, we format it with the 
        // fallback / default language
        catch (e) {
            const ngPipe = new DecimalPipeAngular(this.translationServive.defaultLang);
            return ngPipe.transform(value, digits);
        }
    }
}