import { AfterViewInit, Component } from '@angular/core';
import { ToastrService } from '../../../../../shared/toastr';
import { BetSlipCombinationService, BetSlipService } from '../../../../core/bet';
import { SeasonService } from '../../../../core/season';
import { LogicComponent } from '../logic/logic.component';
import { ModalController, ModalService, ModalType } from '../../../../shared/modal';
import { MemberService } from '../../../../core/member/service/member.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-bet-betslip-mobile',
    templateUrl: './mobile.component.html',
    styleUrls: ['./mobile.component.scss']
})
export class MobileComponent extends LogicComponent implements AfterViewInit {

    constructor(
        protected toastrService: ToastrService,
        protected modalService: ModalService,
        protected betSlipService: BetSlipService,
        protected betSlipCombiationService: BetSlipCombinationService,
        protected seasonService: SeasonService,
        protected memberService: MemberService,
        protected router:Router
    ) {
        super(toastrService, modalService, betSlipService, betSlipCombiationService, seasonService, memberService,router);
    }

    /**
     * scroll down after view init
     */
    public ngAfterViewInit(): void {
        setTimeout(() => this.scrollToBottom(), 100);
    }

    /**
     * extends the original success handling with an additional
     * call to close the BetSlipModal
     * 
     * @param loadingController 
     */
    public handleSubmitSuccess(loadingController: ModalController): void {
        super.handleSubmitSuccess(loadingController);
        setTimeout(() => this.closeBetSlip(), 500);
    }

    /**
     * close the betSlip Modal
     */
    public closeBetSlip(): void {
        this.modalService.closeType(ModalType.BetSlip);
    }
}