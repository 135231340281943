export enum NotificationModeEnum {
    // general types
    Modal = "MODAL",
    Notify = "NOTIFY",
    Email = "EMAIL",
    // extended types
    Account = "ACCOUNT",
    Hidden = "HIDDEN",
    // special types
    BetSlipWin = "BET_SLIP_WIN",
    BetSlipLose = "BET_SLIP_LOSE",
    BetSlipRefund = "BET_SLIP_REFUND",
    BetSlipBestOf = "BET_SLIP_BESTOF",
    AchievementComplete = "ACHIEVEMENT_COMPLETE",
    DailyMissionComplete = "DAILY_MISSION_COMPLETE",
    SeasonRanking = "SEASON_RANKING",
    SeasonWinLadderUpdate = "SEASON_WINLADDER_UPDATE",
}