<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <form (submit)="onSearchSubmit()">
        <div class="row">
            <div class="col-12 col-sm-10 mx-auto text-center">
                <h2 class="tigerfont text-uppercase mb-3">{{ 'bettingGame.invitation.open.title' | translate }}</h2>
            </div>
            <div class="col-12 col-sm-10 mx-auto">
                <ng-autocomplete
                    placeholder="{{ 'bettingGame.invitation.open.input.placeholder' | translate }}"
                    [data]="membersToSelect"
                    [isLoading]="isLoading"
                    [searchKeyword]="keyword"
                    (selected)='onSelected($event)'
                    (inputChanged)='onSearch($event)'
                    (inputFocused)='onFocused($event)'
                    (inputCleared)='onCleared()'
                    [itemTemplate]="suggestionTemplate"
                    [notFoundTemplate]="notFoundTemplate">
                </ng-autocomplete>
                <!-- suggestion template (dropdown) -->
                <ng-template #suggestionTemplate let-member>
                    <div class="suggestion">
                        <div class="suggestion-avatar">
                            <div class="profile-avatar" [style.background-image]="getPicture(member)"></div>
                        </div>
                        <div class="suggestion-username">
                         <span [innerHTML]="member.username"></span>
                        </div>
                        <div class="suggestion-coins">
                            <span>{{ 'coins' | translate }}:</span> {{ member.progress.coins }}
                        </div>
                        <div class="suggestion-xp">
                            <span>{{ 'xp' | translate }}:</span> {{ member.progress.xp }}
                        </div>
                        <div class="suggestion-league">
                            <span>{{ 'league.title' | translate }}:</span> {{ member.progress.winLadder.name }}
                        </div>
                    </div>
                </ng-template>
                <!-- not found tempate (dropdown) -->
                <ng-template #notFoundTemplate let-notFound>
                    <ng-container *ngIf="!isEmailSearch()">
                        <div class="suggestion-not-found">{{ 'bettingGame.invitation.open.error.autocomplete' | translate }}</div>
                    </ng-container>
                </ng-template>
            </div>
        </div>
    </form>

    <!-- selected member -->
    <div class="selected-member" >
      <ng-container *ngIf="isMemberSelected()">
        <div class="profile-avatar" [style.background-image]="getPicture(selected)"></div>
        <div class="username">{{ selected.username }}</div>
        <div class="coins"><span>{{ 'coins' | translate }}:</span> {{ selected.progress.coins }}</div>
        <div class="xp"><span>{{ 'xp' | translate }}:</span> {{ selected.progress.xp }}</div>
        <div class="league"><span>{{ 'league.title' | translate }}:</span> {{ selected.progress.winLadder.name }}</div>
      </ng-container>
    </div>
    <!-- selected email -->
    <div class="selected-email" *ngIf="isEmailSelected()">
        <div class="headline">Email zum einladen</div>
        <div class="email">{{ selected }}</div>
    </div>


</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">{{ 'bettingGame.invitation.open.button.cancel' | translate }}</button>
    <button type="button" class="btn btn-primary" [disabled]="!canInvite()" (click)="invite()">{{ 'bettingGame.invitation.open.button.invite' | translate }}</button>
</div>
