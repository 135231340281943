import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ConnectionService } from '../../../../core/api';
import { HttpService } from '../../../../core/http';
import { MemberService } from '../../member';
import { NotificationListInterface } from '../interface/notification-list.interface';
import { NotificationInterface } from '../interface/notification.interface';

/**
 * api url for notifications
 */
const apiNotificationsUrl = `${environment.api.request}/notification`;


@Injectable({
    providedIn: 'root'
})
export class NotificationLoaderService {
    /**
     * all read notifiactions
     */
    private notificationsReadSubject: BehaviorSubject<NotificationListInterface>;

    /**
     * all unread notifiactions
     */
    private notificationsUnreadSubject: BehaviorSubject<NotificationInterface[]>;

    /**
     * indicatates the loading state for read notifications
     */
    private isLoadingRead: boolean = false;

    /**
     * indicatates the loading state for unread notifications
     */
    private isLoadingUnread: boolean = false;


    
    constructor(
        private httpService: HttpService,
        private memberService: MemberService
    ) {
        this.notificationsReadSubject = new BehaviorSubject<NotificationListInterface>(null);
        this.notificationsUnreadSubject = new BehaviorSubject<NotificationInterface[]>(null);
    }

    /**
     * returns a page of read notifications
     * 
     * @param page 
     * @returns 
     */
    public getReadPage(page: number = 1): Observable<NotificationListInterface> {
        // if page is not currently loading
        if (!this.isLoadingRead) {
            page = (page <= 0) ? 1 : page;
            this.notificationsReadSubject.next(null);
            return this.loadReadPage(page);
        }
        // transform current notification subject into an observable and return it
        return this.notificationsReadSubject.asObservable().pipe(
            filter(notifications => notifications != null), first()
        );
    }

    /**
     * return a observable for notifications
     * 
     * @returns 
     */
    public getUnreadNotificationsObservable(): Observable<NotificationInterface[]> {
        return this.notificationsUnreadSubject.asObservable();
    }

    /**
     * loads unread notifications from api
     */
    public loadUnreadNotifications(): Observable<NotificationInterface[]> {
        if (this.isLoadingUnread || this.memberService.getMemberSnapshot() === null) {
            return this.getUnreadNotificationsObservable().pipe(filter(notifications => notifications != null), first());
        }
        this.clearUnread();
        this.isLoadingUnread = true;
        return this.loadUnread().pipe(
            tap((notifications: NotificationInterface[]) => {
                this.notificationsUnreadSubject.next(notifications);
                this.isLoadingUnread = false;
            }));
    }

    /**
     * clears all notifications
     */
    public clear(): void {
        this.clearRead();
        this.clearUnread();
    }

    /**
     * clears the unread notification subject
     */
    public clearRead(): void {
        this.notificationsReadSubject.next(null);
    }

    /**
     * clears the unread notification subject
     */
    public clearUnread(): void {
        this.notificationsUnreadSubject.next(null);
    }

    /**
     * loads read notifications per page
     * 
     * @param page 
     * @returns 
     */
    private loadReadPage(page: number): Observable<NotificationListInterface> {
        this.isLoadingRead = true;
        const params = new HttpParams().set("page", '' + page).set("ts", '' + Date.now());
        return this.loadRead(params).pipe(
            tap((notifications) => {
                this.notificationsReadSubject.next(notifications);
                this.isLoadingRead = false;
            }));
    }

    /**
     * loads read data from api
     */
    private loadRead(params: {[key: string]: any}): Observable<NotificationListInterface> {
        return this.httpService.get<NotificationListInterface>(`${apiNotificationsUrl}/read`, params, []);
    }

    /**
     * loads unread data from api
     */
    private loadUnread(): Observable<NotificationInterface[]> {
        return this.httpService.get<NotificationInterface[]>(`${apiNotificationsUrl}/unread`, null, []);
    }
}