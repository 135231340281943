import { DatePipe as DatePipeAngular } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../../core/translation';

@Pipe({
    name: 'date'
})
export class DatePipe implements PipeTransform {
    constructor(
        private translationServive: TranslationService
    ) { }

    public transform(value: any, pattern: string = 'mediumDate'): any {
        // try to transfom the date data with with the current language
        // set in the translation service
        try {
            const ngPipe = new DatePipeAngular(this.translationServive.getSelectedLocale());
            return ngPipe.transform(value, pattern);
        }
        // on error trasnaform the date data with the fallback language
        catch (e) {
            const ngPipe = new DatePipeAngular(this.translationServive.defaultLang);
            return ngPipe.transform(value, pattern);
        }
    }
}
