import { GoogleInitOptions } from '@abacritt/angularx-social-login';
import { environment } from '../../../../../environments/environment';

/**
 * define the google login options
 * https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig
 */
export const GoogleLoginOptions:GoogleInitOptions = {
  scopes: environment.google.login.scope,
  oneTapEnabled:false,
  prompt:""
};
